import { useContext } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "../../Assets/icons";
import { LoanReqContext } from "../../Providers/LoanReqProvider";

const TablePagination = () => {
  const { pagination, changePage } = useContext(LoanReqContext);
  const { currentPage, totalPages } = pagination;

  // Handle page number click
  const handleClick = (e) => {
    const selectedPage = Number(e.target.id);
    if (selectedPage !== currentPage) {
      changePage(selectedPage);
    }
  };

  // Handle previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      changePage(currentPage - 1);
    }
  };

  // Handle next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      changePage(currentPage + 1);
    }
  };

  // Render page numbers
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  const renderPageNumbers =
    pages.length > 0 ? (
      pages.map((number) => (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border p-2 text-lg font-semibold ${
            currentPage === number
              ? "bg-blue-500 text-white"
              : "bg-white text-gray-700"
          }`}
        >
          {number}
        </li>
      ))
    ) : (
      <li className="flex h-8 w-8 items-center justify-center rounded-full border bg-white p-2 text-lg font-semibold">
        No Data
      </li>
    );

  return (
    <div className="p-3">
      {totalPages > 0 ? (
        <ul className="flex items-center justify-end gap-3">
          {/* Previous Button */}
          <li>
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1} // Disable when on first page
              aria-label="Previous Page"
            >
              <ArrowLeftIcon />
            </button>
          </li>

          {/* Page Numbers */}
          {renderPageNumbers}

          {/* Next Button */}
          <li>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages} // Disable when on last page
              aria-label="Next Page"
            >
              <ArrowRightIcon />
            </button>
          </li>
        </ul>
      ) : (
        <p className="text-center">No Data Found</p>
      )}
    </div>
  );
};

export default TablePagination;
