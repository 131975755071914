import React, { createContext, useState } from "react";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "./AuthProvider";
import { ENDPOINT } from "../App/config/endpoint";
import { useEffect } from "react";
import toast from "react-hot-toast";
export const LoanReqContext = createContext(null);
const LoanReqProvider = ({ children }) => {
  const [LoanReqData, setLoanReqData] = useState(null);
  const [userListData, setUserListData] = useState(null);
  const [closeList, setCloseList] = useState([]);
  const [dueLoans, setDueLoans] = useState([]);
  const [rollover, setRollover] = useState([]);
  const [repayment, setRepayment] = useState([]);
  const [pendingReq, setPendingReq] = useState([]);
  const [approveReq, setApproveReq] = useState([]);
  const [canceledReq, setCanceledReq] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [singleLoanDetails, setSingleLoanDetails] = useState(null);
  const [singleLoading, setSingleLoading] = useState(false);
  const { token } = useContext(AuthContext);
  const [openLoans, setOpenLoans] = useState([]);

  // Pagination state
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
  });

  console.log(pagination);

  const changePage = (newPage) => {
    console.log(newPage);
    if (newPage >= 1 && newPage <= pagination?.totalPages) {
      updatePagination(newPage, pagination?.totalPages);
    }
  };

  // Open User List
  const updatePagination = (currentPage, totalPages) => {
    setPagination((prev) => ({
      ...prev,
      currentPage,
      totalPages,
    }));
  };

  const getOpenLoanList = (form_date_value, to_date_value) => {
    if (!token) return;

    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.loanList}`;
    const postData = {
      perpage: pagination?.itemsPerPage.toString(),
      page: pagination?.currentPage.toString(),
      payment_status: "",
      loan_status: "open",
      request_status: "",
      from_date: form_date_value,
      to_date: to_date_value,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        console.log("API Response:", res);
        const loan_list = res?.data?.data;
        setOpenLoans(loan_list);
        updatePagination(pagination?.currentPage, res?.data?.totalPage || 1);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  // Close User List
  const getCloseLoanList = (form_date_value, to_date_value) => {
    if (!token) return;

    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.loanList}`;
    const postData = {
      perpage: pagination.itemsPerPage.toString(),
      page: pagination.currentPage.toString(),
      payment_status: "",
      loan_status: "close",
      request_status: "",
      from_date: form_date_value,
      to_date: to_date_value,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        const loan_list = res?.data?.data;
        setCloseList(loan_list);
        updatePagination(pagination.currentPage, res?.data?.totalPage || 1);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  // Due User List
  const getDueLoanList = (form_date_value, to_date_value) => {
    if (!token) return;

    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.loanList}`;
    const postData = {
      perpage: pagination.itemsPerPage.toString(),
      page: pagination.currentPage.toString(),
      payment_status: "",
      collection_stage: "S1",
      loan_status: "open",
      request_status: "",
      from_date: form_date_value,
      to_date: to_date_value,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        const loan_list = res?.data?.data;
        setDueLoans(loan_list);
        updatePagination(pagination.currentPage, res?.data?.totalPage || 1);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  // Rollover List
  const getRolloverList = (form_date_value, to_date_value) => {
    if (!token) return;

    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.rolloverTransaction}`;
    const postData = {
      perpage: pagination.itemsPerPage.toString(),
      page: pagination.currentPage.toString(),
      // payment_status: "",
      // loan_status: "rollover",
      // request_status: "",
      // from_date: form_date_value,
      // to_date: to_date_value,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        const loan_list = res?.data?.data;
        setRollover(loan_list);
        updatePagination(pagination.currentPage, res?.data?.totalPage || 1);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };
  // Repayment List
  const getRepaymentList = (form_date_value, to_date_value) => {
    if (!token) return;

    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.repaymentTransaction}`;
    const postData = {
      perpage: pagination.itemsPerPage.toString(),
      page: pagination.currentPage.toString(),
      // payment_status: "",
      // loan_status: "rollover",
      // request_status: "",
      // from_date: form_date_value,
      // to_date: to_date_value,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        const loan_list = res?.data?.data;
        console.log(loan_list,"loan list");
        setRepayment(loan_list);
        updatePagination(pagination.currentPage, res?.data?.totalPage || 1);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  // Pending Request List
  const getPendingLoanList = (form_date_value, to_date_value) => {
    if (!token) return;

    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.loanList}`;
    const postData = {
      perpage: pagination.itemsPerPage.toString(),
      page: pagination.currentPage.toString(),
      payment_status: "",
      loan_status: "",
      request_status: "pending",
      from_date: form_date_value,
      to_date: to_date_value,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        const loan_list = res?.data?.data;
        setPendingReq(loan_list);
        updatePagination(pagination.currentPage, res?.data?.totalPage || 1);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  // Approved Request List
  const getApprovedReqList = () => {
    if (!token) return;

    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.loanList}`;
    const postData = {
      perpage: pagination.itemsPerPage.toString(),
      page: pagination.currentPage.toString(),
      payment_status: "",
      loan_status: "",
      request_status: "approve",
      from_date: "",
      to_date: "",
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        const loan_list = res?.data?.data;
        setApproveReq(loan_list);
        updatePagination(pagination.currentPage, res?.data?.totalPage || 1);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  // Canceled Request List
  const getCanceledReqList = () => {
    if (!token) return;

    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.loanList}`;
    const postData = {
      perpage: pagination.itemsPerPage.toString(),
      page: pagination.currentPage.toString(),
      payment_status: "",
      loan_status: "",
      request_status: "cancel",
      from_date: "",
      to_date: "",
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        const loan_list = res?.data?.data;
        setCanceledReq(loan_list);
        updatePagination(pagination.currentPage, res?.data?.totalPage || 1);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };
  // Get Single Loan List
  const getSingleLoanDetails = (id) => {
    if (!token) return;

    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.loanDetails}`;
    const postData = {
      loan_id: id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        const loan_list = res?.data?.data;
        setSingleLoanDetails(loan_list);
        updatePagination(pagination.currentPage, res?.data?.totalPage || 1);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    getOpenLoanList();
    getCloseLoanList();
    getDueLoanList();
    getRolloverList();
    getPendingLoanList();
    getApprovedReqList();
    getCanceledReqList();
    getRepaymentList();
  }, [pagination.currentPage]); // Add pagination.currentPage as a dependency

  const infoValue = {
    singleLoanDetails,
    setSingleLoanDetails,
    getSingleLoanDetails,
    singleLoading,
    setLoanReqData,
    LoanReqData,
    setUserListData,
    userListData,
    getOpenLoanList,
    openLoans,
    pagination,
    setPagination,
    closeList,
    getCloseLoanList,
    dueLoans,
    getDueLoanList,
    rollover,
    getRolloverList,
    getRepaymentList,
    pendingReq,
    getPendingLoanList,
    approveReq,
    getApprovedReqList,
    customerId,
    setCustomerId,
    getCanceledReqList,
    canceledReq,
    changePage,
    repayment,
    setRepayment,
  };
  return (
    <LoanReqContext.Provider value={infoValue}>
      {children}
    </LoanReqContext.Provider>
  );
};
export default LoanReqProvider;
