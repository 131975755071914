import { Link, useLocation } from "react-router-dom";
import LifeTime from "../smallComponent/LifeTime";
import BackBtn from "../smallComponent/BackBtn";

const CommonNavBar = ({ pageTitle, calender, backBtn }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  // -----------Path name set is end----------
  return (
    <div className="mx-auto flex items-center justify-between bg-white">
      <nav aria-label="breadcrumb">
        <h1 className="mb-2 font-poppins text-[23px] font-semibold text-secondBlack md:text-[24px] lg:text-[30px]">
          {pageTitle? pageTitle : 'Dashboard'}
        </h1>
        <ol className="breadcrumb flex flex-col space-x-2 pl-[2px] xs:flex-row gap-[2px] xs:gap-3">
          <li className="breadcrumb-item">
            <Link
              to="/"
              className="breadcrumb-item font-poppins text-[15px] font-medium capitalize text-blue-500 hover:underline md:text-[17px]"
            >
              <span className="mr-1 inline xs:hidden text-gray-500">{">"}</span>
              Home
            </Link>
          </li>
          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;
            const displayName =
              name.length > 20
                ? name.replace(name, "").replace("-", " ")
                : name.replace("-", " ");

            const locaTionName = displayName.replace("-", " ");

            return (
              <li
                className="breadcrumb-item flex items-center font-poppins text-[15px] font-medium capitalize md:text-[17px] !ml-0 mt-[1px]"
                key={name}
              >
                {locaTionName ? (
                  <span className="mr-1 text-gray-500">{">"}</span>
                ) : (
                  ""
                )}
                {isLast ? (
                  <span className="text-gray-500">{locaTionName}</span>
                ) : name.length > 20 ? (
                  <span className="text-red-500">{locaTionName}</span>
                ) : (
                  <Link
                    to={
                      locaTionName === "loan request" ||
                      locaTionName === "profile" ||
                      locaTionName === "setting" ||
                      locaTionName === "loan"
                        ? ""
                        : routeTo
                    }
                    className={`${
                      locaTionName === "loan request" ||
                      locaTionName === "profile" ||
                      locaTionName === "setting" ||
                      locaTionName === "loan"
                        ? "text-gray-500"
                        : " text-blue-500 hover:underline"
                    }`}
                  >
                    {locaTionName}
                  </Link>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
      <div>
        {calender === false ? backBtn ? <BackBtn /> : <LifeTime /> : ""}
      </div>
    </div>
  );
};

export default CommonNavBar;
