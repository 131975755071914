import { useLocation } from "react-router-dom";
import CustomerHeaderContent from "./CustomerHeaderContent";
import CustomerInformationContent from "./CustomerInformationContent";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Providers/AuthProvider";
import {
  StatusIcon,
  TotalLoanIcon,
  TotalLoanPaidIcon,
  TotalLoanRecivedIcon,
} from "../../Assets/userProfile/userProfile";
import { ENDPOINT } from "../../App/config/endpoint";
import axios from "axios";
import dummyImg from "../../dummy.jpg";

const CustomerProfileContent = ({ data }) => {
  // console.log("data", data);
  const { token } = useContext(AuthContext);
  const customerId = data?.id;
  // console.log(customerId);
  const [userDetails, setUserDetails] = useState();
  console.log("user Details", userDetails);

  const getSingleCustomerDetails = () => {
    if (!token) {
      return;
    } else {
      const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.user.userDetails}`;
      const postData = {
        user_id: customerId,
      };
      // console.log(',', postData);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.token}`,
      };

      axios
        .post(apiUrl, postData, { headers })
        .then((res) => {
          const userDetailsData = res.data.data;
          // console.log('user dttt', res);
          setUserDetails(userDetailsData);
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    }
  };
  useEffect(() => {
    getSingleCustomerDetails();
  }, [customerId]);

  console.log(userDetails);

  return (
    <div className="mx-auto">
      <div className="bg-white">
        <div>
          <div className="mt-[54px] flex flex-col-reverse items-start justify-between gap-y-[20px] sm:gap-[30px] lg:flex-row">
            {/* Left side data */}
            <div className="grid w-full grid-cols-1 gap-[20px] md:grid-cols-2 lg:gap-[10px] xl:gap-[30px]">
              <div className="flex flex-row items-start justify-between rounded-[16px] px-[15px] py-[10px] text-left shadow-customerShadow lg:flex-row lg:items-start lg:text-left xl:items-end xl:text-left">
                <div>
                  <h2 className="font-inter text-[24px] font-semibold text-secondBlack ">
                    {userDetails?.totalLoanCount > 0 ? (
                      `₹${userDetails?.totalLoanCount}`
                    ) : (
                      <span className="text-gray-400">₹0</span>
                    )}
                  </h2>
                  <p className="font-poppins text-[16px] font-normal text-secondBlack ">
                    Total Loan Received
                  </p>
                </div>
                <div>
                  <TotalLoanRecivedIcon></TotalLoanRecivedIcon>
                </div>
              </div>
              <div className="flex flex-row items-end justify-between rounded-[16px] px-[15px] py-[10px] text-left shadow-customerShadow lg:flex-row lg:items-start lg:text-left xl:items-end xl:text-left">
                <div>
                  <h2 className="font-inter text-[24px] font-semibold text-secondBlack ">
                    {userDetails?.totalLoanCount > 0 ? (
                      `₹${userDetails?.totalLoanCount}`
                    ) : (
                      <span className="text-gray-400">₹0</span>
                    )}
                  </h2>
                  <p className="font-poppins text-[16px] font-normal text-secondBlack ">
                    Total Loan Paid
                  </p>
                </div>
                <div>
                  <TotalLoanPaidIcon></TotalLoanPaidIcon>
                </div>
              </div>
              <div className="flex flex-row items-end justify-between rounded-[16px] px-[15px] py-[10px] text-left shadow-customerShadow lg:flex-row lg:items-start lg:text-left xl:items-end xl:text-left">
                <div>
                  <h2 className="font-inter text-[24px] font-semibold text-secondBlack ">
                    {userDetails?.totalLoanCount || (
                      <span className="text-gray-400">0</span>
                    )}
                  </h2>
                  <p className="font-poppins text-[16px] font-normal text-secondBlack ">
                    Total Loan
                  </p>
                </div>
                <div>
                  <TotalLoanIcon></TotalLoanIcon>
                </div>
              </div>
              <div className="flex flex-row items-end justify-between rounded-[16px] px-[15px] py-[10px] text-left shadow-customerShadow lg:flex-row lg:items-start lg:text-start xl:items-end xl:text-left">
                <div>
                  <h2 className="font-inter text-[20px] font-semibold text-secondBlack ">
                    {userDetails?.customerBarge || (
                      <span className="text-gray-400">N/A</span>
                    )}
                  </h2>
                  <p className="font-poppins text-[16px] font-normal text-secondBlack ">
                    Status
                  </p>
                </div>
                <div>
                  <StatusIcon></StatusIcon>
                </div>
              </div>
            </div>

            {/* Right side image */}
            <div className="mt-[10px] w-full max-w-[220px] rounded-xl border shadow-lg lg:scale-110">
              <img
                src={userDetails?.user_image || dummyImg}
                alt="Customer"
                className="h-[170px] w-full rounded-xl object-cover"
              />
            </div>
          </div>
        </div>
        <CustomerInformationContent key={customerId} data={userDetails} />
      </div>
    </div>
  );
};

export default CustomerProfileContent;
