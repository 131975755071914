import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BlackSearchIcon } from "../../Assets/icons";
import { BsThreeDots } from "react-icons/bs";
import { Menu } from "@headlessui/react";
import TablePagination from "../Shared/TablePagination";
import SharedModal from "../Modals/SharedModal";
import CustomerProfileContent from "../ModalComponent/CustomerProfileContent";
import LoanContent from "../ModalComponent/LoanContent";
import { BsCurrencyRupee } from "react-icons/bs";
import { useContext } from "react";
import { AuthContext } from "../../Providers/AuthProvider";
import { LoanReqContext } from "../../Providers/LoanReqProvider";
import EditProduct from "../../Pages/AdminPanel/Product/EditProduct";
import DeleteCompo from "./DeleteCompo";
import SmallModal from "../Modals/SmallModal";
import Transaction from "../ModalComponent/Transaction";
import ViewTransaction from "../ModalComponent/ViewTransaction";

const CustomTable = ({
  tableData = "[]",
  changeFont,
  tableHead,
  searchOption,
  pageTitle,
  createAgent,
  createProduct,
  searchMethod,
  addOrCreate,
  addOrCreateUrl,
  getLoanList,
  actionBtn,
  url,
  transData,
}) => {
  console.log(tableData);
  const { pathname } = useLocation();
  console.log(pathname);
  const { setCustomerId } = useContext(LoanReqContext);
  const [dateFilter, setDateFilter] = useState("");
  // console.log("data is", tableData);
  const [tableDatas2, setTableDatas2] = useState(tableData);
  const [searchState, setSearchState] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [smallModalOpen, setSmallModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState(false);

  const [selectedOption, setSelectedOption] = useState("Lifetime");

  const location = useLocation();
  const currentAddress = location.pathname.replace("/", "");

  // Search Bar Code
  useEffect(() => {
    const filter = () => {
      if (searchState !== "" || dateFilter !== "") {
        const filteredArray = tableData?.filter((dat) => {
          const matchesSearch =
            dat[searchMethod]
              ?.toLowerCase()
              .includes(searchState.toLowerCase()) ||
            dat?.order_no?.toLowerCase().includes(searchState.toLowerCase()) ||
            dat?.user_no?.toLowerCase().includes(searchState.toLowerCase()) ||
            dat?.full_name?.toLowerCase().includes(searchState.toLowerCase());

          const matchesDate =
            !dateFilter || new Date(dat.closing_date) >= new Date(dateFilter);
          console.log(matchesSearch);
          return matchesSearch && matchesDate;
        });

        setTableDatas2(filteredArray);
      } else {
        setTableDatas2(tableData);
      }
    };

    filter();
  }, [searchState, searchMethod, dateFilter, tableData]);

  // pagination state
  const [data, setData] = useState(tableDatas2);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // Pagination Item Set
  useEffect(() => {
    setData(tableDatas2);
    setcurrentPage(1);
  }, [tableDatas2]);

  // Modal Function
  const handleAction = (e, data) => {
    // console.log("datas", data);
    setModalOpen(true);
    // console.log(currentAddress);
    const btn = e.target.innerText;

    setModalAction(true);
    // setModalContent(
    //   <AgentContent key={data.id} data={data} setModalOpen={setModalOpen} />
    // );
    if (btn === "View Profile") {
      // console.log("customer profile data: ", data);
      setModalContent(
        <CustomerProfileContent
          key={data.id}
          data={data}
          setModalOpen={setModalOpen}
        />
      );

      setModalTitle("Customer Profile");
    } else if (btn === "Edit Details") {
      setModalContent(
        <LoanContent
          key={data.id}
          data={data}
          setModalOpen={setModalOpen}
          paymentStatus={true}
          dueAmount={false}
          closedLoan={true}
          requestStatus={false}
          closingDate={true}
          loanStatus={true}
        />
      );
      setModalTitle("Edit Details");
    } else if (btn === "Edit Request") {
      setModalContent(
        <LoanContent
          key={data.id}
          data={data}
          setModalOpen={setModalOpen}
          paymentStatus={false}
          dueAmount={false}
          closedLoan={true}
          requestStatus={true}
          closingDate={true}
          loanStatus={false}
        />
      );
      setModalTitle("Edit Request");
    } else if (btn === "Transaction Details") {
      setModalContent(
        <>
          {pathname === "/loan/rollover" || pathname === "/loan/repayment" ? (
            <ViewTransaction
              key={data?.id}
              data={transData}
              setModalOpen={setModalOpen}
              pathname={pathname}
            />
          ) : (
            <Transaction
              key={data?.id}
              data={data}
              setModalOpen={setModalOpen}
              pathname={pathname}
            />
          )}
        </>
      );
      setModalTitle("Transaction Details");
    } else {
      setModalAction("Something wen't wrong, please try again");
    }
  };

  // Delete Modal
  const handleDelete = (e, id, name) => {
    e.preventDefault();
    setModalTitle("Delete Product");
    setSmallModalOpen(true);
    setModalContent(
      <DeleteCompo setModalOpen={setSmallModalOpen} id={id} name={name} />
    );
  };

  // Current date
  const currentDate = new Date();
  const formattedDate = formatDate(currentDate);

  // Calculate the date one week (7 days) before the current date
  const oneWeekBefore = new Date(currentDate);
  oneWeekBefore.setDate(currentDate.getDate() - 7);
  const formattedDateOneWeekBefore = formatDate(oneWeekBefore);

  // Calculate the date one month before the current date
  const oneMonthBefore = new Date(currentDate);
  oneMonthBefore.setMonth(currentDate.getMonth() - 1);
  const formattedDateOneMonthBefore = formatDate(oneMonthBefore);

  // Calculate the date one year (365 days) before the current date
  const oneYearBefore = new Date(currentDate);
  oneYearBefore.setDate(currentDate.getDate() - 365);
  const formattedDateOneYearBefore = formatDate(oneYearBefore);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (selectedOption === "Daily") {
      getLoanList(formattedDate, formattedDate);
    } else if (selectedOption === "Weekly") {
      getLoanList(formattedDate, formattedDateOneWeekBefore);
    } else if (selectedOption === "Monthly") {
      getLoanList(formattedDate, formattedDateOneMonthBefore);
    } else if (selectedOption === "Yearly") {
      // console.log(formattedDate, formattedDateOneYearBefore);
      getLoanList(formattedDate, formattedDateOneYearBefore);
    } else if (selectedOption === "Lifetime") {
      getLoanList("", "");
    }
  }, [selectedOption]);

  return (
    <div className="mx-auto pb-[60px]">
      <div className="flex flex-col items-center justify-between gap-4 py-8 md:flex-row">
        <div>
          <h3 className="font-poppins text-[18px] font-semibold text-black lg:text-2xl">
            {pageTitle}
          </h3>
        </div>

        {searchOption && (
          <>
            {createProduct && <div className="mb-[10px] xs:hidden"></div>}
            <div className="flex w-full flex-col items-center justify-center gap-4 sm:flex-row md:w-[unset]">
              {/* {!createProduct && (
                <div className="-mt-[2px]">
                  <select
                    name="status"
                    className="mt-1 w-[150px] cursor-pointer rounded-lg border bg-white px-1 py-[9px] text-left font-inter text-[15px] font-normal capitalize text-secondBlack focus:text-secondBlack focus:outline-none"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  >
                    <option value="Lifetime">Life time</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>
              )} */}
              <div className="flex h-[38px] w-full items-center  ">
                {!createProduct && (
                  <div className="flex gap-2">
                    {" "}
                    <div>
                      <input
                        type="date"
                        className="rounded-md px-4 py-[7px]"
                        value={dateFilter}
                        onChange={(e) => setDateFilter(e.target.value)}
                      />
                    </div>
                    <input
                      type="text"
                      className="relative w-full rounded-l-lg border bg-white px-4 py-2 pl-4 font-poppins text-black focus:outline-none"
                      placeholder={
                        currentAddress.includes("loan") &&
                        "Search customer name..."
                      }
                      defaultValue={searchState}
                      onChange={(e) => setSearchState(e.target.value)}
                      required={true}
                    />
                  </div>
                )}
                {createAgent ? (
                  <>
                    <button className="absolute rounded-l-lg border-y bg-transparent bg-white py-2 pl-2 text-base font-semibold text-black md:right-[440px]">
                      <BlackSearchIcon />
                    </button>
                    <Link
                      to={addOrCreateUrl}
                      className="w-[200px] rounded-r-lg border bg-black px-3 py-2 text-base font-semibold text-white"
                    >
                      {addOrCreate}
                    </Link>
                  </>
                ) : createProduct ? (
                  <>
                    <div className="mt-[40px] flex w-full flex-col items-center justify-center gap-4 xs:mt-0 md:w-[unset] md:flex-row">
                      <div className="relative z-[0] mb-6 flex h-[115px] w-full flex-col items-center gap-2 xs:mb-0 xs:h-[38px] xs:w-fit xs:flex-row xs:gap-0">
                        <input
                          type="text"
                          className="relative w-full rounded-lg border bg-white px-4 py-2 pl-[30px] font-poppins text-black focus:outline-none xs:rounded-r-none"
                          placeholder={
                            currentAddress.includes("product") &&
                            "Search product name..."
                          }
                          defaultValue={searchState}
                          onChange={(e) => setSearchState(e.target.value)}
                          required={true}
                        />
                        <button className="absolute left-[0] rounded-l-lg border-y bg-transparent bg-white py-[10px] pl-2 text-base font-semibold text-black">
                          <BlackSearchIcon />
                        </button>
                        <Link
                          to={addOrCreateUrl}
                          className="w-full rounded-lg border bg-black px-3 py-[9px] pt-[10px] text-center text-[14px] font-semibold text-white xs:w-[225px] xs:rounded-l-none xs:text-left sm:py-[8px] sm:text-base"
                        >
                          {addOrCreate}
                        </Link>
                      </div>
                    </div>
                  </>
                ) : (
                  <button className="rounded-r-lg border bg-white px-3 py-[10px] text-base font-semibold text-black">
                    <BlackSearchIcon />
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="rounded-lg bg-white pb-3 shadow-tableShadow">
        <div className="overflow-x-scroll">
          <table className="w-full">
            <thead className="w-full bg-[#F1F3F7]">
              <tr className="h-16 w-full">
                {tableHead?.map((thead) => {
                  return (
                    <th
                      style={{ minWidth: thead.width }}
                      // width={thead.width}
                      key={thead.id}
                      className={`p-16px px-2 text-left ${
                        changeFont ? changeFont : "font-hahmlet"
                      } text-base font-medium text-black`}
                    >
                      {thead.name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="relative w-full gap-3">
              {currentItems?.map((tbody, i) => {
                // console.log("tbody", tbody);
                return (
                  <tr
                    key={i}
                    className="h-28 bg-white text-sm leading-none text-gray-800 hover:bg-gray-100"
                  >
                    {Object.entries(tbody).map(([key, item], index) => {
                      // Check if the key is "id"
                      if (key === "id" || key === "user_id") {
                        return null; // Skip rendering
                      } else if (
                        key === "user_image" ||
                        key === "product_img"
                      ) {
                        return (
                          <td className="px-2">
                            <div className="text-left font-poppins text-base font-normal">
                              <img
                                src={tbody?.user_image}
                                className=" !h-[70px] !w-[70px] rounded-2xl object-cover"
                                alt="img"
                              />
                            </div>
                          </td>
                        );
                      } else if (key === "applicant_status") {
                        return (
                          <td className="px-2">
                            {tbody?.applicant_status === "New Customer" ? (
                              <span className="rounded-full bg-[#63AD6F1A] px-3 py-1 text-left font-poppins text-base font-normal capitalize text-[#63AD6F]">
                                {tbody?.applicant_status}
                              </span>
                            ) : tbody?.applicant_status === "Re-applied" ? (
                              <span className="rounded-full bg-[#F75F001A] px-3 py-1 text-left font-poppins text-base font-normal capitalize text-[#F75F00]">
                                {tbody?.applicant_status}
                              </span>
                            ) : (
                              <span className="rounded-full bg-[#398CC51A] px-3 py-1 text-left font-poppins text-base font-normal capitalize text-[#398CC5]">
                                {tbody?.applicant_status}
                              </span>
                            )}
                          </td>
                        );
                      }

                      return (
                        <td className="px-2" key={index}>
                          <div className="text-left font-poppins text-base font-normal">
                            {typeof item === "number" ? (
                              <p className="flex items-center">
                                {key === "loan_interest_rate" ? (
                                  ""
                                ) : (
                                  <BsCurrencyRupee size={17} />
                                )}
                                {item}
                                {key === "loan_interest_rate" ? "%" : ""}
                              </p>
                            ) : item === "paid" ? (
                              <span className="rounded-full bg-[#176BAD1A] px-3 py-1 text-[#439AD1]">
                                Paid
                              </span>
                            ) : item === "unpaid" ? (
                              <span className="rounded-full bg-[#F768681A] px-3 py-1 text-[#F76868]">
                                Unpaid
                              </span>
                            ) : item === "open" ? (
                              <span className="rounded-full bg-[#176BAD1A] px-3 py-1 text-[#439AD1]">
                                Open
                              </span>
                            ) : item === "close" ? (
                              <span className="rounded-full bg-[#F768681A] px-3 py-1 text-[#F76868]">
                                Close
                              </span>
                            ) : item === "rollover" ? (
                              <span className="rounded-full bg-[#F4831A1A] px-3 py-1 text-[#F4831A]">
                                Rollover
                              </span>
                            ) : item === "overdue" ? (
                              <span className="rounded-full bg-[#F768681A] px-3 py-1 capitalize text-[#F76868]">
                                {item}
                              </span>
                            ) : item === "pending" ? (
                              <span className="rounded-full bg-[#F4831A1A] px-3 py-1 text-[#F4831A]">
                                Pending
                              </span>
                            ) : item === "approve" ? (
                              <span className="rounded-full bg-[#63AD6F1A] px-3 py-1 text-[#63AD6F]">
                                Approved
                              </span>
                            ) : item === "cancel" ? (
                              <span className="rounded-full bg-[#F4831A1A] px-3 py-1 text-[#F4831A]">
                                Canceled
                              </span>
                            ) : (
                              <p>{item}</p>
                            )}
                          </div>
                        </td>
                      );
                    })}

                    <td className="relative px-2">
                      <Menu>
                        <Menu.Button>
                          <span className="text-left font-poppins text-base font-normal">
                            <BsThreeDots />
                          </span>
                        </Menu.Button>
                        <Menu.Items className="absolute left-[-147px] z-[10] flex min-w-[170px] flex-col border bg-white">
                          {actionBtn.length > 0
                            ? actionBtn.map((btn, index) =>
                                btn === "Customers Profile" ? (
                                  <Menu.Item key={index}>
                                    {({ active }) => (
                                      <Link
                                        onClick={() => setCustomerId(tbody.id)}
                                        to={`/customers/profile/${encodeURIComponent(
                                          tbody?.user_id
                                        )}`}
                                        className={`mx-auto block w-full rounded-md p-[10px] text-center font-inter text-[16px] font-normal hover:bg-[#F0F2F5] ${
                                          active ? "bg-[#F0F2F5]" : ""
                                        }`}
                                      >
                                        {btn}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ) : btn === "View Details" ? (
                                  <Menu.Item key={index}>
                                    {({ active }) => (
                                      <Link
                                        onClick={() =>
                                          setCustomerId(tbody.user_no)
                                        }
                                        to={`/${currentAddress}/${encodeURIComponent(
                                          tbody.id
                                        )}/loan-request-details`}
                                        className={`mx-auto block w-full rounded-md p-[10px] text-center font-inter text-[16px] font-normal hover:bg-[#F0F2F5] ${
                                          active ? "bg-[#F0F2F5]" : ""
                                        }`}
                                      >
                                        {btn}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ) : btn === "View Profile" ? (
                                  <Menu.Item
                                    key={index}
                                    onClick={(e) => handleAction(e, tbody)}
                                  >
                                    {({ active }) => (
                                      <Link
                                        className={`mx-auto block w-full rounded-md p-[10px] text-center font-inter text-[16px] font-normal hover:bg-[#F0F2F5] ${
                                          active ? "bg-[#F0F2F5]" : ""
                                        }`}
                                      >
                                        {btn}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ) : btn === "Edit Details" ? (
                                  <Menu.Item
                                    key={index}
                                    onClick={(e) => handleAction(e, tbody)}
                                  >
                                    {({ active }) => (
                                      <Link
                                        className={`mx-auto block w-full rounded-md p-[10px] text-center font-inter text-[16px] font-normal hover:bg-[#F0F2F5] ${
                                          active ? "bg-[#F0F2F5]" : ""
                                        }`}
                                      >
                                        {btn}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ) : btn === "Edit Request" ? (
                                  <Menu.Item
                                    key={index}
                                    onClick={(e) => handleAction(e, tbody)}
                                  >
                                    {({ active }) => (
                                      <Link
                                        className={`mx-auto block w-full rounded-md p-[10px] text-center font-inter text-[16px] font-normal hover:bg-[#F0F2F5] ${
                                          active ? "bg-[#F0F2F5]" : ""
                                        }`}
                                      >
                                        {btn}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ) : btn === "Edit Product" ? (
                                  <Menu.Item key={index}>
                                    {({ active }) => (
                                      <Link
                                        to={`/${currentAddress}/edit/${encodeURIComponent(
                                          tbody.id
                                        )}`}
                                        className={`mx-auto block w-full rounded-md p-[10px] text-center font-inter text-[16px] font-normal hover:bg-[#F0F2F5] ${
                                          active ? "bg-[#F0F2F5]" : ""
                                        }`}
                                      >
                                        {btn}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ) : btn === "Transaction Details" ? (
                                  <Menu.Item
                                    key={index}
                                    onClick={(e) => handleAction(e, tbody)}
                                  >
                                    {({ active }) => (
                                      <Link
                                        className={`mx-auto block w-full rounded-md p-[10px] text-center font-inter text-[16px] font-normal hover:bg-[#F0F2F5] ${
                                          active ? "bg-[#F0F2F5]" : ""
                                        }`}
                                      >
                                        {btn}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ) : btn === "Delete" ? (
                                  <Menu.Item
                                    key={index}
                                    onClick={(e) =>
                                      handleDelete(e, tbody.id, tbody.name)
                                    }
                                  >
                                    {({ active }) => (
                                      <button
                                        className={`mx-auto block w-full rounded-md p-[10px] text-center font-inter text-[16px] font-normal hover:bg-[#F0F2F5] ${
                                          active ? "bg-[#F0F2F5]" : ""
                                        }`}
                                      >
                                        {btn}
                                      </button>
                                    )}
                                  </Menu.Item>
                                ) : (
                                  <Menu.Item
                                    onClick={(e) => handleAction(e, tbody)}
                                  >
                                    {({ active }) => (
                                      <Link
                                        className={`w-full rounded-md p-[10px] text-center font-inter text-[16px] font-normal hover:bg-[#F0F2F5] ${
                                          active ? "bg-[#F0F2F5]" : ""
                                        }`}
                                      >
                                        {btn}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                )
                              )
                            : null}
                        </Menu.Items>
                      </Menu>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <TablePagination
          data={data}
          currentPage={currentPage}
          setcurrentPage={setcurrentPage}
          itemsPerPage={itemsPerPage}
        />

        <SharedModal
          title={modalTitle}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          modalContent={modalContent}
          handleAction={handleAction}
          modalAction={modalAction}
        />
        <SmallModal
          title={modalTitle}
          modalOpen={smallModalOpen}
          setModalOpen={setSmallModalOpen}
          modalContent={modalContent}
        />
      </div>
    </div>
  );
};

export default CustomTable;
