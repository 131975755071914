import React, { useEffect, useState } from "react";
import PrimaryLayout from "../../../Component/layouts/PrimaryLayout";
import ProductInfo from "../../../Component/dashboard/ProductInfo";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ENDPOINT } from "../../../App/config/endpoint";

const EditProduct = () => {
  const [detailsProduct, setDetailsProduct] = useState({});
  const selectOptions1 = [
    { name: "7" },
    { name: "15" },
    { name: "30" },
    { name: "60" },
    { name: "90" },
  ];
  const selectOptions2 = [
    { name: 0.00 },
    { name: 5 },
    { name: 10 },
    { name: 12 },
    { name: 20 },
    { name: 30 },
    { name: 40 },
    { name: 50 },
  ];
  const selectOptions3 = [
    { name: 0 },
    { name: 3500 },
    { name: 3600 },
    { name: 5000 },
    { name: 8000 },
    { name: 10000 },
  ];
  const [selected1, setSelected1] = useState(() => selectOptions1[null]);
  const [selected2, setSelected2] = useState(() => selectOptions2[null]);
  const [selected3, setSelected3] = useState(() => selectOptions3[null]);

  const { id } = useParams();
  // console.log(id);

  const token = JSON.parse(localStorage.getItem("authInfo"));
  const getProductDetailsList = () => {
    if (!token) {
      return;
    } else {
      const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.product.productDetails}`;
      const postData = {
        product_id: id,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.token}`,
      };
      // console.log(postData);
      axios
        .post(apiUrl, postData, { headers })
        .then((response) => {
          let detailsData = response.data.data;
          setDetailsProduct(detailsData);
          // console.log(detailsData);

          // For Loan Term Selected Option
          const selected1Data = selectOptions1.find(select => select.name === detailsData.loan_terms);
          setSelected1(selected1Data)

          // For Interest Selected Option loan_amount
          const selected2Data = selectOptions2.find(select => select.name === detailsData.loan_interest_rate);
          setSelected2(selected2Data)

          // For Loan Amount Selected Option 
          const selected3Data = selectOptions3.find(select => select.name === detailsData.loan_amount);
          setSelected3(selected3Data)
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  //   For Call Function
  useEffect(() => {
    getProductDetailsList();
  }, [token?.token]);

  return (
    <PrimaryLayout pageTitle="Edit Product">
      <div className="bg-white px-[18px] pt-[20px] lg:px-[30px] pb-8">
        <ProductInfo
          selected1={selected1}
          selected2={selected2}
          selected3={selected3}
          setSelected1={setSelected1}
          setSelected2={setSelected2}
          setSelected3={setSelected3}
          selectOptions1={selectOptions1}
          selectOptions2={selectOptions2}
          selectOptions3={selectOptions3}
          detailsProduct={detailsProduct}
          productId = {id}
        />
      </div>
    </PrimaryLayout>
  );
};

export default EditProduct;
