export const UploadImgIcon = () => {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.3335" cy="24" r="24" fill="#F0F2F5" />
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M31.8336 27.334C32.2609 27.334 32.6132 27.6557 32.6613 28.0701L32.6669 28.1673V29.834C32.6669 31.1654 31.6261 32.2537 30.3138 32.3297L30.1669 32.334H18.5002C17.1688 32.334 16.0805 31.2932 16.0045 29.9809L16.0002 29.834V28.1673C16.0002 27.7071 16.3733 27.334 16.8336 27.334C17.2609 27.334 17.6132 27.6557 17.6613 28.0701L17.6669 28.1673V29.834C17.6669 30.2613 17.9886 30.6136 18.4031 30.6617L18.5002 30.6673H30.1669C30.5943 30.6673 30.9465 30.3456 30.9946 29.9312L31.0002 29.834V28.1673C31.0002 27.7071 31.3733 27.334 31.8336 27.334ZM24.2099 15.6762L24.2586 15.6703L24.3336 15.667L24.3963 15.6693L24.5008 15.6839L24.5937 15.7084L24.6863 15.7451L24.7675 15.7887L24.8548 15.8501L24.9228 15.9111L27.4228 18.4111C27.7483 18.7365 27.7483 19.2641 27.4228 19.5896C27.0974 19.915 26.5698 19.915 26.2443 19.5896L25.1669 18.512V26.5003C25.1669 26.9606 24.7938 27.3337 24.3336 27.3337C23.8733 27.3337 23.5002 26.9606 23.5002 26.5003V18.512L22.4228 19.5896C22.0974 19.915 21.5698 19.915 21.2443 19.5896C20.9189 19.2641 20.9189 18.7365 21.2443 18.4111L23.7443 15.9111C23.7738 15.8816 23.805 15.8548 23.8375 15.8306L23.9302 15.7709L24.0241 15.7263L24.1119 15.6969L24.2099 15.6762Z"
        fill="#333333"
      />
    </svg>
  );
};

export const AcIcon = () => {
  return (
    <svg
      fill="none"
      height="24"
      shape-rendering="geometricPrecision"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M6 9l6 6 6-6"></path>
    </svg>
  );
};
export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M10.2014 17.3977C14.1772 17.3977 17.4002 14.1746 17.4002 10.1988C17.4002 6.22303 14.1772 3 10.2014 3C6.22559 3 3.00256 6.22303 3.00256 10.1988C3.00256 14.1746 6.22559 17.3977 10.2014 17.3977Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5444 17.9496C16.9684 19.2294 17.9362 19.3574 18.6801 18.2375C19.36 17.2137 18.912 16.3738 17.6802 16.3738C16.7684 16.3658 16.2565 17.0777 16.5444 17.9496Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const BlackSearchIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.43377 18.473C14.1466 18.473 17.9671 14.6525 17.9671 9.93963C17.9671 5.22677 14.1466 1.40625 9.43377 1.40625C4.72091 1.40625 0.900391 5.22677 0.900391 9.93963C0.900391 14.6525 4.72091 18.473 9.43377 18.473Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1 20.6058L15.46 15.9658"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7.14392 13V1"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 7H1"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ArrowRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-right"
    >
      <polyline points="9 18 15 12 9 6" />
    </svg>
  );
};
export const ArrowLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-left"
    >
      <polyline points="15 18 9 12 15 6" />
    </svg>
  );
};
export const ThreeDotIcon = () => {
  return (
    <svg
      width="24"
      height="6"
      viewBox="0 0 24 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3" cy="3" r="2.5" fill="#333333" />
      <circle cx="12" cy="3" r="2.5" fill="#333333" />
      <circle cx="21" cy="3" r="2.5" fill="#333333" />
    </svg>
  );
};
export const CartIcon = () => {
  return (
    <svg
      className=""
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.09 6.97978C20.24 6.03978 18.82 5.56978 16.76 5.56978H16.52V5.52978C16.52 3.84978 16.52 1.76978 12.76 1.76978H11.24C7.48004 1.76978 7.48004 3.85978 7.48004 5.52978V5.57978H7.24004C5.17004 5.57978 3.76004 6.04978 2.91004 6.98978C1.92004 8.08978 1.95004 9.56977 2.05004 10.5798L2.06004 10.6498L2.13749 11.4631C2.15176 11.6128 2.23242 11.7481 2.35831 11.8305C2.59812 11.9874 2.99946 12.2461 3.24004 12.3798C3.38004 12.4698 3.53004 12.5498 3.68004 12.6298C5.39004 13.5698 7.27004 14.1998 9.18004 14.5098C9.27004 15.4498 9.68004 16.5498 11.87 16.5498C14.06 16.5498 14.49 15.4598 14.56 14.4898C16.6 14.1598 18.57 13.4498 20.35 12.4098C20.41 12.3798 20.45 12.3498 20.5 12.3198C20.8968 12.0955 21.3083 11.8193 21.6835 11.5486C21.7965 11.4671 21.8688 11.341 21.8842 11.2025L21.9 11.0598L21.95 10.5898C21.96 10.5298 21.96 10.4798 21.97 10.4098C22.05 9.39977 22.03 8.01978 21.09 6.97978ZM13.09 13.8298C13.09 14.8898 13.09 15.0498 11.86 15.0498C10.63 15.0498 10.63 14.8598 10.63 13.8398V12.5798H13.09V13.8298ZM8.91004 5.56978V5.52978C8.91004 3.82978 8.91004 3.19978 11.24 3.19978H12.76C15.09 3.19978 15.09 3.83978 15.09 5.52978V5.57978H8.91004V5.56978Z"
        fill="#0D0D10"
      />
      <path
        d="M20.8733 13.7344C21.2269 13.5661 21.6342 13.8464 21.5988 14.2364L21.2398 18.1902C21.0298 20.1902 20.2098 22.2302 15.8098 22.2302H8.18984C3.78984 22.2302 2.96984 20.1902 2.75984 18.2002L2.41913 14.4524C2.38409 14.0669 2.78205 13.787 3.13468 13.9465C4.2741 14.462 6.37724 15.3766 7.67641 15.7169C7.84072 15.7599 7.97361 15.8775 8.04556 16.0314C8.65253 17.3295 9.96896 18.0202 11.8698 18.0202C13.752 18.0202 15.085 17.3029 15.694 16.0016C15.766 15.8476 15.8991 15.7301 16.0635 15.6868C17.443 15.3238 19.6816 14.3015 20.8733 13.7344Z"
        fill="#0D0D10"
      />
    </svg>
  );
};
export const ClockIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44 23.9999C44 35.0399 35.04 43.9999 24 43.9999C12.96 43.9999 4 35.0399 4 23.9999C4 12.9599 12.96 3.99988 24 3.99988C35.04 3.99988 44 12.9599 44 23.9999Z"
        stroke="#555555"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4198 30.3597L25.2198 26.6597C24.1398 26.0197 23.2598 24.4797 23.2598 23.2197V15.0197"
        stroke="#555555"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CustomersIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.7845 14.1091C35.6645 14.0891 35.5245 14.0891 35.4045 14.1091C32.6445 14.0091 30.4445 11.7491 30.4445 8.94907C30.4445 6.08907 32.7445 3.78906 35.6045 3.78906C38.4645 3.78906 40.7645 6.10907 40.7645 8.94907C40.7445 11.7491 38.5445 14.0091 35.7845 14.1091Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.7244 28.6694C36.4644 29.1294 39.4844 28.6494 41.6044 27.2294C44.4244 25.3494 44.4244 22.2694 41.6044 20.3894C39.4644 18.9694 36.4044 18.4894 33.6644 18.9694"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7244 14.1091C11.8444 14.0891 11.9844 14.0891 12.1044 14.1091C14.8644 14.0091 17.0644 11.7491 17.0644 8.94907C17.0644 6.08907 14.7644 3.78906 11.9044 3.78906C9.04445 3.78906 6.74445 6.10907 6.74445 8.94907C6.76445 11.7491 8.96445 14.0091 11.7244 14.1091Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7844 28.6694C11.0444 29.1294 8.02443 28.6494 5.90443 27.2294C3.08443 25.3494 3.08443 22.2694 5.90443 20.3894C8.04443 18.9694 11.1044 18.4894 13.8444 18.9694"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.7845 29.0505C23.6645 29.0305 23.5245 29.0305 23.4045 29.0505C20.6445 28.9505 18.4445 26.6905 18.4445 23.8905C18.4445 21.0305 20.7445 18.7305 23.6045 18.7305C26.4645 18.7305 28.7645 21.0505 28.7645 23.8905C28.7445 26.6905 26.5445 28.9705 23.7845 29.0505Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9644 35.3478C15.1444 37.2278 15.1444 40.3078 17.9644 42.1878C21.1644 44.3278 26.4044 44.3278 29.6044 42.1878C32.4244 40.3078 32.4244 37.2278 29.6044 35.3478C26.4244 33.2278 21.1644 33.2278 17.9644 35.3478Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LoanSoldIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2158 32.3494C18.1773 32.0094 18.0186 31.746 17.7396 31.5592C17.4607 31.37 17.1096 31.2754 16.6864 31.2754C16.3834 31.2754 16.1213 31.3233 15.9001 31.4191C15.6789 31.5125 15.507 31.6418 15.3843 31.8071C15.2641 31.9699 15.204 32.1555 15.204 32.3638C15.204 32.5386 15.2448 32.6895 15.3266 32.8164C15.4108 32.9433 15.5202 33.0499 15.6548 33.1361C15.7919 33.2199 15.9386 33.2905 16.0949 33.348C16.2512 33.4031 16.4015 33.4486 16.5457 33.4845L17.2671 33.6713C17.5028 33.7287 17.7444 33.8066 17.9921 33.9047C18.2398 34.0029 18.4694 34.1322 18.681 34.2927C18.8927 34.4531 19.0634 34.6519 19.1932 34.8889C19.3255 35.126 19.3916 35.4097 19.3916 35.7402C19.3916 36.1569 19.2834 36.5268 19.067 36.8501C18.853 37.1734 18.5416 37.4284 18.1328 37.6152C17.7264 37.802 17.2347 37.8954 16.6576 37.8954C16.1045 37.8954 15.626 37.8079 15.222 37.6331C14.818 37.4583 14.5018 37.2105 14.2734 36.8896C14.0449 36.5663 13.9187 36.1832 13.8947 35.7402H15.0128C15.0344 36.006 15.121 36.2275 15.2725 36.4047C15.4264 36.5795 15.6224 36.71 15.8604 36.7962C16.1009 36.88 16.3642 36.9219 16.6503 36.9219C16.9654 36.9219 17.2455 36.8729 17.4908 36.7747C17.7384 36.6741 17.9332 36.5352 18.0751 36.358C18.217 36.1784 18.2879 35.9689 18.2879 35.7294C18.2879 35.5115 18.2254 35.3331 18.1003 35.1942C17.9777 35.0553 17.8106 34.9404 17.599 34.8494C17.3898 34.7584 17.1529 34.6782 16.8884 34.6087L16.0155 34.3717C15.424 34.2112 14.9551 33.9754 14.6088 33.6641C14.265 33.3528 14.093 32.9409 14.093 32.4285C14.093 32.0046 14.2085 31.6346 14.4393 31.3186C14.6701 31.0025 14.9827 30.757 15.3771 30.5822C15.7715 30.405 16.2163 30.3164 16.7117 30.3164C17.2118 30.3164 17.6531 30.4038 18.0354 30.5786C18.4201 30.7534 18.7231 30.9941 18.9444 31.3006C19.1656 31.6047 19.281 31.9543 19.2906 32.3494H18.2158Z"
        fill="#222222"
      />
      <path
        d="M22.9706 37.8846C22.4512 37.8846 21.9979 37.766 21.6108 37.529C21.2236 37.2919 20.9231 36.9603 20.709 36.534C20.495 36.1078 20.388 35.6097 20.388 35.0398C20.388 34.4675 20.495 33.967 20.709 33.5384C20.9231 33.1097 21.2236 32.7769 21.6108 32.5398C21.9979 32.3027 22.4512 32.1842 22.9706 32.1842C23.49 32.1842 23.9433 32.3027 24.3304 32.5398C24.7175 32.7769 25.0181 33.1097 25.2321 33.5384C25.4461 33.967 25.5532 34.4675 25.5532 35.0398C25.5532 35.6097 25.4461 36.1078 25.2321 36.534C25.0181 36.9603 24.7175 37.2919 24.3304 37.529C23.9433 37.766 23.49 37.8846 22.9706 37.8846ZM22.9742 36.983C23.3108 36.983 23.5898 36.8944 23.811 36.7172C24.0322 36.54 24.1957 36.3041 24.3015 36.0096C24.4098 35.7151 24.4639 35.3906 24.4639 35.0362C24.4639 34.6842 24.4098 34.3609 24.3015 34.0664C24.1957 33.7694 24.0322 33.5312 23.811 33.3516C23.5898 33.172 23.3108 33.0822 22.9742 33.0822C22.6351 33.0822 22.3538 33.172 22.1302 33.3516C21.9089 33.5312 21.7442 33.7694 21.636 34.0664C21.5302 34.3609 21.4773 34.6842 21.4773 35.0362C21.4773 35.3906 21.5302 35.7151 21.636 36.0096C21.7442 36.3041 21.9089 36.54 22.1302 36.7172C22.3538 36.8944 22.6351 36.983 22.9742 36.983Z"
        fill="#222222"
      />
      <path d="M27.8354 30.417V37.7732H26.757V30.417H27.8354Z" fill="#222222" />
      <path
        d="M31.3603 37.881C30.913 37.881 30.5139 37.7672 30.1628 37.5398C29.8141 37.3099 29.54 36.983 29.3404 36.5592C29.1432 36.1329 29.0447 35.6217 29.0447 35.0254C29.0447 34.4292 29.1444 33.9191 29.344 33.4953C29.546 33.0714 29.8226 32.7469 30.1736 32.5218C30.5247 32.2968 30.9227 32.1842 31.3675 32.1842C31.7114 32.1842 31.9879 32.2417 32.1971 32.3566C32.4087 32.4692 32.5722 32.6009 32.6877 32.7517C32.8055 32.9026 32.8969 33.0355 32.9618 33.1504H33.0267V30.417H34.1052V37.7732H33.052V36.9148H32.9618C32.8969 37.0321 32.8031 37.1662 32.6804 37.3171C32.5602 37.4679 32.3943 37.5996 32.1827 37.7122C31.9711 37.8247 31.697 37.881 31.3603 37.881ZM31.5984 36.965C31.9086 36.965 32.1707 36.8836 32.3847 36.7208C32.6011 36.5556 32.7646 36.3269 32.8752 36.0347C32.9882 35.7426 33.0447 35.4026 33.0447 35.0146C33.0447 34.6315 32.9894 34.2963 32.8788 34.0089C32.7682 33.7215 32.6059 33.4976 32.3919 33.3372C32.1779 33.1768 31.9134 33.0966 31.5984 33.0966C31.2737 33.0966 31.0032 33.1804 30.7868 33.348C30.5704 33.5156 30.4069 33.7443 30.2963 34.034C30.1881 34.3238 30.134 34.6507 30.134 35.0146C30.134 35.3834 30.1893 35.7151 30.2999 36.0096C30.4105 36.3041 30.574 36.5376 30.7904 36.71C31.0092 36.88 31.2786 36.965 31.5984 36.965Z"
        fill="#222222"
      />
      <path
        d="M15 15.339V13.399C15 8.89904 18.62 4.47904 23.12 4.05904C28.48 3.53904 33 7.75904 33 13.019V15.779"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 44H30C38.04 44 39.48 40.78 39.9 36.86L41.4 24.86C41.94 19.98 40.54 16 32 16H16C7.46002 16 6.06002 19.98 6.60002 24.86L8.10002 36.86C8.52002 40.78 9.96002 44 18 44Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.991 24H31.0089"
        stroke="#222222"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.989 24H17.007"
        stroke="#222222"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TotalLoanAmountIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5032 33.7207V37.7808C21.5032 41.2208 18.3032 44.0007 14.3632 44.0007C10.4232 44.0007 7.20312 41.2208 7.20312 37.7808V33.7207C7.20312 37.1607 10.4032 39.6007 14.3632 39.6007C18.3032 39.6007 21.5032 37.1407 21.5032 33.7207Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 28.2195C21.5 29.2195 21.22 30.1395 20.74 30.9395C19.56 32.8795 17.14 34.0995 14.34 34.0995C11.54 34.0995 9.11994 32.8595 7.93994 30.9395C7.45994 30.1395 7.18005 29.2195 7.18005 28.2195C7.18005 26.4995 7.98001 24.9595 9.26001 23.8395C10.56 22.6995 12.3399 22.0195 14.3199 22.0195C16.2999 22.0195 18.08 22.7195 19.38 23.8395C20.7 24.9395 21.5 26.4995 21.5 28.2195Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5032 28.22V33.72C21.5032 37.16 18.3032 39.6 14.3632 39.6C10.4232 39.6 7.20312 37.14 7.20312 33.72V28.22C7.20312 24.78 10.4032 22 14.3632 22C16.3432 22 18.1232 22.6999 19.4232 23.8199C20.7032 24.9399 21.5032 26.5 21.5032 28.22Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.0001 21.9403V26.0604C44.0001 27.1604 43.1201 28.0603 42.0001 28.1003H38.08C35.92 28.1003 33.9401 26.5204 33.7601 24.3604C33.6401 23.1004 34.12 21.9203 34.96 21.1003C35.7 20.3403 36.72 19.9004 37.84 19.9004H42.0001C43.1201 19.9404 44.0001 20.8403 44.0001 21.9403Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 21V17C4 11.56 7.28 7.76 12.38 7.12C12.9 7.04 13.44 7 14 7H32C32.52 7 33.02 7.01998 33.5 7.09998C38.66 7.69998 42 11.52 42 17V19.9H37.84C36.72 19.9 35.7 20.34 34.96 21.1C34.12 21.92 33.64 23.1 33.76 24.36C33.94 26.52 35.92 28.1 38.08 28.1H42V31C42 37 38 41 32 41H27"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ClosedLoanIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 28H24"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 11.9199L6.5 4.41992"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.92 4.5L6.42004 12"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 20H30"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 4H32C38.66 4.36 42 6.82 42 15.98V32"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 18.0195V31.9595C6 39.9795 8 43.9995 18 43.9995H24C24.34 43.9995 29.68 43.9995 30 43.9995"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42 32L30 44V38C30 34 32 32 36 32H42Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ActiveLoanIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.62 29.4004L21.62 32.4004L29.62 24.4004"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 12H28C32 12 32 10 32 8C32 4 30 4 28 4H20C18 4 16 4 16 8C16 12 18 12 20 12Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 8.03906C38.66 8.39906 42 10.8591 42 19.9991V31.9991C42 39.9991 40 43.9991 30 43.9991H18C8 43.9991 6 39.9991 6 31.9991V19.9991C6 10.8791 9.34 8.39906 16 8.03906"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const TotalDueLoanIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.08 27.1C35.24 27.92 34.76 29.1 34.88 30.36C35.06 32.52 37.04 34.1 39.2 34.1H43V36.48C43 40.62 39.62 44 35.48 44H15.28C16.94 42.54 18 40.4 18 38C18 33.58 14.42 30 10 30C8.12 30 6.38 30.66 5 31.76V23.02C5 18.88 8.37999 15.5 12.52 15.5H35.48C39.62 15.5 43 18.88 43 23.02V25.9H38.96C37.84 25.9 36.82 26.34 36.08 27.1Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 23.0193V15.6793C5 13.2993 6.45999 11.1792 8.67999 10.3392L24.56 4.33921C27.04 3.41921 29.7 5.23927 29.7 7.89927V15.4992"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.1176 27.9403V32.0604C45.1176 33.1604 44.2376 34.0603 43.1176 34.1003H39.1976C37.0376 34.1003 35.0576 32.5204 34.8776 30.3604C34.7576 29.1004 35.2376 27.9203 36.0776 27.1003C36.8176 26.3403 37.8376 25.9004 38.9576 25.9004H43.1176C44.2376 25.9404 45.1176 26.8403 45.1176 27.9403Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 24H28"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 38C18 40.4 16.94 42.54 15.28 44C13.86 45.24 12.02 46 10 46C5.58 46 2 42.42 2 38C2 35.48 3.16 33.22 5 31.76C6.38 30.66 8.12 30 10 30C14.42 30 18 33.58 18 38Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 35.5V38.5L8 40"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DefaultLoanIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 16.5V36C40 42 36.42 44 32 44H16C11.58 44 8 42 8 36V16.5C8 10 11.58 8.5 16 8.5C16 9.74 16.4999 10.86 17.3199 11.68C18.1399 12.5 19.26 13 20.5 13H27.5C29.98 13 32 10.98 32 8.5C36.42 8.5 40 10 40 16.5Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 8.5C32 10.98 29.98 13 27.5 13H20.5C19.26 13 18.1399 12.5 17.3199 11.68C16.4999 10.86 16 9.74 16 8.5C16 6.02 18.02 4 20.5 4H27.5C28.74 4 29.8601 4.50001 30.6801 5.32001C31.5001 6.14001 32 7.26 32 8.5Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 26H24"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 34H32"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const TotalDefaultLoanIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.08 27.1C35.24 27.92 34.76 29.1 34.88 30.36C35.06 32.52 37.04 34.1 39.2 34.1H43V36.48C43 40.62 39.62 44 35.48 44H12.52C8.38 44 5 40.62 5 36.48V23.02C5 18.88 8.38 15.5 12.52 15.5H35.48C39.62 15.5 43 18.88 43 23.02V25.9H38.96C37.84 25.9 36.82 26.34 36.08 27.1Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 24.8206V15.6807C5 13.3007 6.46 11.1806 8.68 10.3406L24.56 4.34061C27.04 3.40061 29.7 5.24067 29.7 7.90067V15.5006"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.1176 27.9403V32.0604C45.1176 33.1604 44.2376 34.0603 43.1176 34.1003H39.1976C37.0376 34.1003 35.0576 32.5204 34.8776 30.3604C34.7576 29.1004 35.2376 27.9203 36.0776 27.1003C36.8176 26.3403 37.8376 25.9004 38.9576 25.9004H43.1176C44.2376 25.9404 45.1176 26.8403 45.1176 27.9403Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 24H28"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const LoanRequestIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 39H42"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 25H42"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 11H42"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 11L8 13L14 7"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 25L8 27L14 21"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 39L8 41L14 35"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CancelRequestIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 32.3204L20.08 24.4004"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.92 24.4805L20 32.4005"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 12H28C32 12 32 10 32 8C32 4 30 4 28 4H20C18 4 16 4 16 8C16 12 18 12 20 12Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 8.03906C38.66 8.39906 42 10.8591 42 19.9991V31.9991C42 39.9991 40 43.9991 30 43.9991H18C8 43.9991 6 39.9991 6 31.9991V19.9991C6 10.8791 9.34 8.39906 16 8.03906"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TotalGrossReveneuIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5234 30.877H18.5234"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5234 25.9961V35.9961"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.32 5.03627L25.26 5.17627L19.46 18.6363H13.76C12.4 18.6363 11.1 18.9163 9.92004 19.4163L13.42 11.0563L13.5 10.8563L13.64 10.5363C13.68 10.4163 13.72 10.2963 13.78 10.1963C16.4 4.13627 19.36 2.75627 25.32 5.03627Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.1 19.0371C35.2 18.7571 34.24 18.6371 33.28 18.6371H19.46L25.26 5.17711L25.32 5.03711C25.62 5.13711 25.9 5.27711 26.2 5.39711L30.62 7.25711C33.08 8.27711 34.8 9.33711 35.84 10.6171C36.04 10.8571 36.2 11.0771 36.34 11.3371C36.52 11.6171 36.66 11.8971 36.74 12.1971C36.82 12.3771 36.88 12.5571 36.92 12.7171C37.46 14.3971 37.14 16.4571 36.1 19.0371Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.0434 28.3967V32.2967C43.0434 32.6967 43.0234 33.0967 43.0034 33.4967C42.6234 40.4767 38.7234 43.9967 31.3234 43.9967H15.7234C15.2434 43.9967 14.7634 43.9567 14.3034 43.8967C7.94344 43.4767 4.54344 40.0767 4.12344 33.7167C4.06344 33.2567 4.02344 32.7767 4.02344 32.2967V28.3967C4.02344 24.3767 6.46344 20.9167 9.94344 19.4167C11.1434 18.9167 12.4234 18.6367 13.7834 18.6367H33.3034C34.2834 18.6367 35.2434 18.7767 36.1234 19.0367C40.1034 20.2567 43.0434 23.9767 43.0434 28.3967Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.42 11.0566L9.92 19.4166C6.44 20.9166 4 24.3766 4 28.3966V22.5366C4 16.8566 8.04 12.1166 13.42 11.0566Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.0372 22.5353V28.3953C43.0372 23.9953 40.1172 20.2553 36.1172 19.0553C37.1572 16.4553 37.4572 14.4153 36.9572 12.7153C36.9172 12.5353 36.8572 12.3553 36.7772 12.1953C40.4972 14.1153 43.0372 18.0553 43.0372 22.5353Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const TotalLossIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5234 31.9961H18.5234"
        stroke="#222222"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.32 5.03627L25.26 5.17627L19.46 18.6363H13.76C12.4 18.6363 11.1 18.9163 9.92004 19.4163L13.42 11.0563L13.5 10.8563L13.64 10.5363C13.68 10.4163 13.72 10.2963 13.78 10.1963C16.4 4.13627 19.36 2.75627 25.32 5.03627Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.1 19.0371C35.2 18.7571 34.24 18.6371 33.28 18.6371H19.46L25.26 5.17711L25.32 5.03711C25.62 5.13711 25.9 5.27711 26.2 5.39711L30.62 7.25711C33.08 8.27711 34.8 9.33711 35.84 10.6171C36.04 10.8571 36.2 11.0771 36.34 11.3371C36.52 11.6171 36.66 11.8971 36.74 12.1971C36.82 12.3771 36.88 12.5571 36.92 12.7171C37.46 14.3971 37.14 16.4571 36.1 19.0371Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.0434 28.3967V32.2967C43.0434 32.6967 43.0234 33.0967 43.0034 33.4967C42.6234 40.4767 38.7234 43.9967 31.3234 43.9967H15.7234C15.2434 43.9967 14.7634 43.9567 14.3034 43.8967C7.94344 43.4767 4.54344 40.0767 4.12344 33.7167C4.06344 33.2567 4.02344 32.7767 4.02344 32.2967V28.3967C4.02344 24.3767 6.46344 20.9167 9.94344 19.4167C11.1434 18.9167 12.4234 18.6367 13.7834 18.6367H33.3034C34.2834 18.6367 35.2434 18.7767 36.1234 19.0367C40.1034 20.2567 43.0434 23.9767 43.0434 28.3967Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.42 11.0566L9.92 19.4166C6.44 20.9166 4 24.3766 4 28.3966V22.5366C4 16.8566 8.04 12.1166 13.42 11.0566Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.0372 22.5353V28.3953C43.0372 23.9953 40.1172 20.2553 36.1172 19.0553C37.1572 16.4553 37.4572 14.4153 36.9572 12.7153C36.9172 12.5353 36.8572 12.3553 36.7772 12.1953C40.4972 14.1153 43.0372 18.0553 43.0372 22.5353Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
