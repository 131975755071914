import React from "react";
import PrimaryLayout from "../../../Component/layouts/PrimaryLayout";

const WebSupport = () => {
  return (
    <PrimaryLayout pageTitle="Web Support">
      <div className="bg-[#F0F2F5] px-[18px] py-[30px] lg:px-[30px]">
        <div className="rounded-lg bg-[#fff] p-[25px] sm:p-[40px]"></div>
      </div>
    </PrimaryLayout>
  );
};

export default WebSupport;
