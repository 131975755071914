import {
  CRMIcon,
  CollectionAgentIcon,
  CustomersIcon,
  DashboardIcon,
  LoanIcon,
  LoanRequest,
  LogoutIcon,
  ProductIcon,
  Setting,
  SubmenuIcon,
} from "./SideNavigationIcons";
import { IoLink } from "react-icons/io5";
export const sidebarDatas = [
  {
    id: 1,
    title: "MENU",
    mainMenus: [
      {
        id: 11,
        name: "Dashboard",
        url: "/",
        icon: <DashboardIcon />,
        submenu: [],
      },
    ],
  },
  {
    id: 2,
    title: "",
    mainMenus: [
      {
        id: 12,
        name: "Customers",
        url: "/customers",
        icon: <CustomersIcon />,
        submenu: [],
      },
    ],
  },
  {
    id: 3,
    title: "",
    mainMenus: [
      {
        id: 13,
        name: "Collection Agent",
        url: "/collection-agent",
        icon: <CollectionAgentIcon />,
        submenu: [],
      },
    ],
  },

  {
    id: 4,
    title: "",
    mainMenus: [
      {
        id: 14,
        name: "Product",
        url: "/product",
        icon: <ProductIcon />,
        submenu: [],
      },
    ],
  },
  {
    id: 45345,
    title: "",
    mainMenus: [
      {
        id: 196,
        name: "App Link",
        url: "/link",
        icon: <IoLink className="text-[20px]" />,
        submenu: [],
      },
    ],
  },
  {
    id: 5,
    title: "",
    mainMenus: [
      {
        id: 15,
        name: "Loan",
        url: "",
        icon: <LoanIcon />,
        submenu: [
          {
            id: 100,
            name: "Open Loan",
            url: "/loan/open-loan",
            icon: <SubmenuIcon />,
          },
          {
            id: 200,
            name: "Due Loan",
            url: "/loan/due-loan",
            icon: <SubmenuIcon />,
          },
          {
            id: 3,
            name: "Close Loan",
            url: "/loan/close-loan",
            icon: <SubmenuIcon />,
          },
          {
            id: 4,
            name: "Rollover",
            url: "/loan/rollover",
            icon: <SubmenuIcon />,
          },
          {
            id: 5,
            name: "Repayment",
            url: "/loan/repayment",
            icon: <SubmenuIcon />,
          },
        ],
      },
    ],
  },
  {
    id: 6,
    title: "",
    mainMenus: [
      {
        id: 16,
        name: "Loan Request",
        url: "",
        icon: <LoanRequest />,
        submenu: [
          {
            id: 100,
            name: "Pending Request",
            url: "/loan-request/pending-request",
            icon: <SubmenuIcon />,
          },
          {
            id: 200,
            name: "Approved Request",
            url: "/loan-request/approved-request",
            icon: <SubmenuIcon />,
          },
          {
            id: 3,
            name: "Canceled Request",
            url: "/loan-request/canceled-request",
            icon: <SubmenuIcon />,
          },
        ],
      },
    ],
  },

  {
    id: 8,
    title: "setting",
    mainMenus: [
      {
        id: 18,
        name: "Setting",
        url: "",
        icon: <Setting />,
        submenu: [
          // {
          //   id: 1230,
          //   name: "Sent Notification",
          //   url: "/setting/sent-notification",
          //   icon: <SubmenuIcon />,
          // },
          {
            id: 2230,
            name: "Change Password",
            url: "/setting/change-password",
            icon: <SubmenuIcon />,
          },
          {
            id: 24240,
            name: "Accounts",
            url: "/setting/account",
            icon: <SubmenuIcon />,
          },
          // {
          //   id: 2240,
          //   name: "Web Support",
          //   url: "/setting/web-support",
          //   icon: <SubmenuIcon />,
          // },
        ],
      },
    ],
  },
];
