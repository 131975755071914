import React from "react";

const ViewTransaction = ({ data }) => {
  console.log(data);

  const { id, date, utr, amount, voucher_img } = data;
  return (
    <div>
      <h2>Rollover Transaction Details</h2>

      <div className="group-open:animate-fadeIn mb-3 mt-1 px-3">
        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
          <div className="">
            <h3 className="font-poppins text-[14px] font-medium capitalize  leading-[29.05px] text-secondBlack">
              Date
            </h3>
            <div className="flex  min-h-[40px] items-center overflow-hidden rounded-lg border px-[10px] font-poppins text-[14px] font-normal  capitalize leading-[29.05px] text-secondBlack shadow-sm">
              {date || <span className="">0</span>}
            </div>
          </div>
          <div className="">
            <h3 className="font-poppins text-[14px] font-medium capitalize  leading-[29.05px] text-secondBlack">
              Amount
            </h3>
            <div className="flex  min-h-[40px] items-center rounded-lg border px-[10px] font-poppins text-[14px] font-normal  capitalize leading-[29.05px] text-secondBlack shadow-sm">
              ₹{amount || <span className="">0</span>}
            </div>
          </div>
          <div>
            <h3 className="font-poppins text-[14px] font-medium capitalize  leading-[29.05px] text-secondBlack">
              UTR Number
            </h3>
            <div className="flex  min-h-[40px] items-center rounded-lg border px-[10px] font-poppins text-[14px] font-normal  capitalize leading-[29.05px] text-secondBlack shadow-sm">
              {utr || <span className="text-gray-400">N/A</span>}
            </div>
          </div>
        </div>
        <div className="mt-1">
          <h3 className="font-poppins text-[14px] font-medium capitalize  leading-[29.05px] text-secondBlack">
            Voucher
          </h3>
          <img
            src={voucher_img}
            alt="voucher"
            className=" h-[200px] w-[200px] rounded-xl border shadow-sm"
          />
        </div>
      </div>
    </div>
  );
};

export default ViewTransaction;
