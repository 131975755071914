import React, { Fragment, useState } from "react";
import PrimaryLayout from "../../../Component/layouts/PrimaryLayout";
import { Listbox, Transition } from "@headlessui/react";
import { BsArrowRightCircleFill, BsChevronDown } from "react-icons/bs";

const SentNotification = () => {
  const selectOptions2 = [
    { name: "All Customer" },
    { name: "All Agent" },
    { name: "Text Message to All" },
  ];
  const [selected2, setSelected2] = useState(() => selectOptions2[null]);
  return (
    <PrimaryLayout pageTitle="Notification">
      <div className="bg-[#F0F2F5] px-[18px] lg:px-[30px] py-[30px]">
        <div className="rounded-lg bg-[#fff] p-[25px] sm:p-[40px]">
          <form>
            <div className="w-full">
              <h3 className="font-inter text-[18px] font-medium text-black  ">
                Notification Type
              </h3>

              {/* Selection Item 1 in Modal */}
              <Listbox value={selected2} onChange={setSelected2}>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full rounded-md border border-secondBlack bg-white px-2 py-[6px] text-left font-inter text-[17px] font-medium text-secondBlack focus:text-secondBlack focus:outline-none  ">
                    <span className="block truncate">
                      {selected2 ? selected2.name : "Select Type"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <BsChevronDown
                        className="-mr-1 ml-2 h-5 w-5 text-secondBlack hover:text-black"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-[10000] mt-2 w-full overflow-auto rounded-lg border bg-white px-2 py-[10px] text-[18px] shadow-selectShadow  ">
                      {selectOptions2.map((singleOption, singleOptionIdx) => (
                        <Listbox.Option
                          key={singleOptionIdx}
                          className={({ active }) =>
                            `relative m-2 cursor-pointer rounded-lg border border-gray-200 py-2 pl-2 text-[18px]   ${
                              active
                                ? "bg-gray-100 text-[#0EAB8B]"
                                : "text-secondBlack"
                            }`
                          }
                          value={singleOption}
                        >
                          {singleOption.name}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>

            <div className="mt-3 w-full">
              <h3 className="font-inter text-[18px] font-medium text-black  ">
                Subject/Title
              </h3>
              <input
                type="text"
                className="relative w-full rounded-md  border border-secondBlack bg-white px-2 py-[6px] text-left font-inter text-[17px] font-medium text-secondBlack focus:text-secondBlack focus:outline-none placeholder:font-normal"
                placeholder="Enter Subject"
              />
            </div>
            <div className="mt-3 w-full">
              <h3 className="font-inter text-[18px] font-medium text-black  ">
                Message
              </h3>
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                className="relative w-full rounded-md  border border-secondBlack bg-white px-2 py-[6px] text-left font-inter text-[17px] font-medium text-secondBlack placeholder:font-normal focus:text-secondBlack focus:outline-none  "
                placeholder="Write a message.."
              ></textarea>
            </div>
            <div className="flex items-center justify-center pt-5">
              <button
                type="submit"
                className="btn w-fit gap-1 bg-black font-poppins flex items-center py-2 rounded-md px-6 text-[18px] text-white hover:bg-secondBlack hover:text-white"
              >
                Sent Notification
                <span className="text-white">
                  <BsArrowRightCircleFill size={20} />
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </PrimaryLayout>
  );
};

export default SentNotification;
