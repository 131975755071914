import React, { Fragment, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function MainItems({ data }) {
  const [dropdown, setDropdown] = useState(false);
  const [activeLoan, setActiveLoan] = useState(true);
  const [innerData, setInnerData] = useState("");

  const refrandom2 = useRef(Math.random());

  const location = useLocation();
  // const mainPath = location.pathname.split("/")[1];

  const activeDropdown = () => {
    if (
      location.pathname === "/loan/open-loan" ||
      location.pathname === "/loan/due-loan" ||
      location.pathname === "/loan/close-loan" ||
      location.pathname === "/loan/rollover"
    ) {
      setActiveLoan(!activeLoan);
      setInnerData("Loan");
    } else if (
      location.pathname === "/loan-request/pending-request" ||
      location.pathname === "/loan-request/approved-request" ||
      location.pathname === "/loan-request/canceled-request"
    ) {
      setActiveLoan(!activeLoan);
      setInnerData("Loan Request");
    }
  };

  return (
    <Fragment>
      <div ref={refrandom2} onClick={() => setDropdown(!dropdown)}>
        <div
          onClick={activeDropdown}
          className={`mb-0 ${dropdown && "mb-[18px]"} cursor-pointer ${
            innerData === data.name && "bg-[#ffffff1a]"
          } flex w-full items-center justify-between rounded px-[16px] py-[13px] text-white transition-all hover:bg-[#ffffff1a] focus:bg-[#ffffff1a] active:bg-[#ffffff1a]`}
        >
          <div className="flex items-center">
            {data.icon}
            <span className="pl-[11px] font-inter text-[14px] font-medium text-white focus:bg-blue-500 active:text-white">
              {data.name}
            </span>
          </div>
          <div>
            {dropdown ? (
              <div className="ml-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-up active:stroke-white group-hover:stroke-white"
                  width={14}
                  height={14}
                  viewBox="0 0 24 24"
                  strokeWidth={1}
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <polyline points="6 15 12 9 18 15" />
                </svg>
              </div>
            ) : (
              <div className="ml-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-down active:stroke-white group-hover:stroke-white"
                  width={14}
                  height={14}
                  viewBox="0 0 24 24"
                  strokeWidth={1}
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <polyline points="6 9 12 15 18 9" />
                </svg>
              </div>
            )}
          </div>
        </div>
        {dropdown ? (
          <div className="ml-8 transition-all">
            <ul className="-mt-[15px]">
              {data?.submenu?.map((data) => {
                return (
                  <Link
                    key={data.id}
                    to={data.url}
                    className={`flex w-full items-center rounded py-[13px] mb-[3px] pl-[5px] pr-[0] ${
                      location.pathname === data.url && "bg-[#ffffff1a]"
                    } text-white transition-all hover:bg-[#ffffff1a]`}
                  >
                    <div className="flex items-center">
                      {data.icon}
                      <span className="pl-[11px] font-inter text-[14px] font-medium text-white focus:bg-blue-500 active:text-white">
                        {data.name}
                      </span>
                    </div>
                  </Link>
                );
              })}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
}
