import React, { Fragment, useContext } from "react";
import axios from "axios";
import { Listbox, Transition } from "@headlessui/react";
import { BsChevronDown } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ENDPOINT } from "../../App/config/endpoint";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../Providers/AuthProvider";
import ImageUploader from "../Shared/ImageUploader";

const ProductInfo = (props) => {
  const { getProductList } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const {
    detailsProduct,
    selected1,
    selected2,
    selected3,
    setSelected1,
    setSelected2,
    setSelected3,
    selectOptions1,
    selectOptions2,
    selectOptions3,
    productId,
  } = props;
  // console.log("details: ", detailsProduct);

  // Get Token From Local Storage
  const token = JSON.parse(localStorage.getItem("authInfo"));

  const updateProductInfo = (e) => {
    setIsLoading(true);
    e.preventDefault();

    const form = e.target;
    const name = form.name.value;
    const start_date = form.start_date.value;
    const end_date = form.end_date.value;
    const loan_terms = selected1 ? selected1.name : form?.payment_status;
    const loan_interest_rate = selected2
      ? selected2.name
      : form?.payment_status;
    const loan_amount = selected3 ? selected3.name : form?.payment_status;
    if (!token) {
      return;
    } else {
      const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.product.productUpdate}`;
      const postData = {
        name,
        start_date,
        end_date,
        loan_terms,
        loan_interest_rate,
        loan_amount,
        product_id: productId,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      };
      axios
        .post(apiUrl, postData, { headers })
        .then((response) => {
          if (response.data.status) {
            toast.success(response.data.message);
            setIsLoading(false);
            getProductList();
          } else {
            toast.error(response.data.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("updated Error: ", error);
        });
    }
  };

  return (
    <div>
      <div>
        <h4 className="mb-[10px] font-poppins text-[17px] font-medium leading-8 text-black">
          Upload Product Image
        </h4>

        <div className="max-w-[300px]">
          <ImageUploader image={profile} setState={setProfile} />
        </div>
      </div>

      <form onSubmit={updateProductInfo}>
        <div className="my-6 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div className="form-control">
            <label className="font-inter text-[17px] font-medium text-secondBlack  ">
              Product Name
            </label>
            <input
              name="name"
              type="text"
              placeholder="Product Name"
              className="input input-bordered mt-1 min-h-[42px] w-full rounded-md   border   border-secondBlack bg-white px-2 text-left font-inter  text-[17px] font-normal text-secondBlack focus:text-secondBlack focus:outline-none"
              defaultValue={detailsProduct?.name}
            />
          </div>
          <div className="form-control">
            <label className="font-inter text-[17px] font-medium text-secondBlack  ">
              Start Date
            </label>
            <input
              name="start_date"
              type="date"
              placeholder="DD/MM/YYYY"
              className="input input-bordered mt-1 min-h-[42px] w-full rounded-md   border   border-secondBlack bg-white px-2 text-left font-inter  text-[17px] font-normal text-secondBlack focus:text-secondBlack focus:outline-none"
              defaultValue={detailsProduct?.start_date}
            />
          </div>
          <div className="form-control">
            <label className="font-inter text-[17px] font-medium text-secondBlack  ">
              Ending Date
            </label>
            <input
              name="end_date"
              type="date"
              placeholder="DD/MM/YYYY"
              className="input input-bordered mt-1 min-h-[42px] w-full rounded-md   border   border-secondBlack bg-white px-2 text-left font-inter  text-[17px] font-normal text-secondBlack focus:text-secondBlack focus:outline-none"
              defaultValue={detailsProduct?.end_date}
            />
          </div>

          <div className="w-full">
            <h3 className="font-inter text-[17px] font-medium text-black  ">
              Loan Terms
            </h3>

            {/* Selection Item 1 in Modal */}
            <Listbox value={selected1} onChange={setSelected1}>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-pointer rounded-lg border border-secondBlack bg-white px-2 py-[6px] text-left font-inter text-[17px] font-normal text-secondBlack focus:text-secondBlack focus:outline-none  ">
                  <span className="block truncate">
                    {selected1 ? (
                      selected1.name
                    ) : (
                      <span className="capitalize">
                        {detailsProduct?.loan_terms}
                      </span>
                    )}{" "}
                    Days
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <BsChevronDown
                      className="-mr-1 ml-2 h-5 w-5 text-secondBlack hover:text-black"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-[10000] mt-2 w-full overflow-auto rounded-lg border bg-white px-1 py-[5px] text-[17px] shadow-selectShadow  ">
                    {selectOptions1.map((singleOption, singleOptionIdx) => (
                      <Listbox.Option
                        key={singleOptionIdx}
                        className={({ active }) =>
                          `relative m-2 cursor-pointer rounded-lg border border-gray-200 py-2 pl-2 text-[17px]   ${
                            active
                              ? "bg-gray-100 text-[#0EAB8B]"
                              : "text-secondBlack"
                          }`
                        }
                        value={singleOption}
                      >
                        {({ selected1 }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected1 ? "font-normal" : "font-normal"
                              }`}
                            >
                              {singleOption.name}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>

          <div className="w-full">
            <h3 className="font-inter text-[17px] font-medium text-black  ">
              Loan Interest Rate
            </h3>

            {/* Selection Item 1 in Modal */}
            <Listbox value={selected2} onChange={setSelected2}>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-pointer rounded-lg border border-secondBlack bg-white px-2 py-[6px] text-left font-inter text-[17px] font-normal text-secondBlack focus:text-secondBlack focus:outline-none   ">
                  <span className="block truncate">{selected2?.name}%</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <BsChevronDown
                      className="-mr-1 ml-2 h-5 w-5 text-secondBlack hover:text-black"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-[10000] mt-2 w-full overflow-auto rounded-lg border bg-white px-1 py-[5px] text-[17px] shadow-selectShadow   ">
                    {selectOptions2.map((singleOption, singleOptionIdx) => (
                      <Listbox.Option
                        key={singleOptionIdx}
                        className={({ active }) =>
                          `relative m-2 cursor-pointer rounded-lg border border-gray-200 py-2 pl-2 text-[17px]   ${
                            active
                              ? "bg-gray-100 text-[#0EAB8B]"
                              : "text-secondBlack"
                          }`
                        }
                        value={singleOption}
                      >
                        {({ selected2 }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected2 ? "font-normal" : "font-normal"
                              }`}
                            >
                              {singleOption.name}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>

          <div className="w-full">
            <h3 className="font-inter text-[17px] font-medium text-black  ">
              Loan Amount
            </h3>

            {/* Selection Item 1 in Modal */}
            <Listbox value={selected2} onChange={setSelected3}>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-pointer rounded-lg border border-secondBlack bg-white px-2 py-[6px] text-left font-inter text-[17px] font-normal text-secondBlack focus:text-secondBlack focus:outline-none   ">
                  <span className="block truncate">₹{selected3?.name}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <BsChevronDown
                      className="-mr-1 ml-2 h-5 w-5 text-secondBlack hover:text-black"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-[10000] mt-2 w-full overflow-auto rounded-lg border bg-white px-1 py-[5px] text-[17px] shadow-selectShadow   ">
                    {selectOptions3.map((singleOption, singleOptionIdx) => (
                      <Listbox.Option
                        key={singleOptionIdx}
                        className={({ active }) =>
                          `relative m-2 cursor-pointer rounded-lg border border-gray-200 py-2 pl-2 text-[17px]   ${
                            active
                              ? "bg-gray-100 text-[#0EAB8B]"
                              : "text-secondBlack"
                          }`
                        }
                        value={singleOption}
                      >
                        {({ selected2 }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected2 ? "font-normal" : "font-normal"
                              }`}
                            >
                              {singleOption.name}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        </div>

        <div className="flex flex-col gap-3 sm:flex-row md:gap-6">
          <div className="w-full sm:w-fit text-center m-0 justify-center rounded bg-[#EF4E57] py-[8px] md:justify-normal">
            <Link
              to="/product"
              className="px-[30px] font-poppins text-[16px] font-medium text-white lg:text-[17px]"
            >
              Cancel
            </Link>
          </div>
          <div className="w-full sm:w-fit flex justify-center rounded bg-black md:justify-normal">
            <button
              type="submit"
              className="px-[30px] py-[8px] font-poppins text-[16px] font-medium text-white lg:text-[17px]"
            >
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProductInfo;
