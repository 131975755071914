import { Listbox, Transition } from "@headlessui/react";
import axios from "axios";
import React, { Fragment, useContext, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { ENDPOINT } from "../../App/config/endpoint";
import toast from "react-hot-toast";
import { AuthContext } from "../../Providers/AuthProvider";
import { LoanReqContext } from "../../Providers/LoanReqProvider";
import { useLocation } from "react-router-dom";

const LoanContent = ({
  data,
  paymentStatus,
  dueAmount,
  closedLoan,
  setModalOpen,
  requestStatus,
  closingDate,
  loanStatus,
  loanAmount,
}) => {
  console.log("mydata: ", data);
  const selectOptions1 = [{ name: "Paid" }, { name: "Unpaid" }];
  const selectOptions2 = [{ name: "Open" }, { name: "Close" }];
  const selectOptions3 = [
    { name: "approve" },
    { name: "pending" },
    { name: "cancel" },
  ];
  const selectOptions4 = [
    { name: "3500" },
    { name: "5000" },
    { name: "8000" },
    { name: "10000" },
  ];
  const [selected1, setSelected1] = useState(() => selectOptions1[null]);
  const [selected2, setSelected2] = useState(() => selectOptions2[null]);
  const [selected3, setSelected3] = useState(() => selectOptions3[null]);
  const [selected4, setSelected4] = useState(() => selectOptions4[null]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    getOpenLoanList,
    getPendingLoanList,
    getApprovedReqList,
    getCanceledReqList,
    getDueLoanList,
    getCloseLoanList,
    getRolloverList,
  } = useContext(LoanReqContext);

  const handleAction = () => {
    setModalOpen(false);
  };

  // Get Token From Local Storage
  const token = JSON.parse(localStorage.getItem("authInfo"));
  const handleUpdateLoan = (e) => {
    setIsLoading(true);
    e.preventDefault();

    const form = e.target;

    const loan_id = data.id;
    let closing_date = form?.close_date?.value;
    let due_amount = form?.due_amount?.value;
    let payment_status = selected1 ? selected1.name : data?.payment_status;
    let loan_status = selected2 ? selected2.name : data?.loan_status;
    let request_status = selected3 ? selected3.name : data?.request_status;
    let loan_amount = selected4 ? selected4.name : data?.loan_amount;

    if (!token) {
      return;
    } else {
      const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.updateLoan}`;
      const postData = {
        loan_id: loan_id,
        closing_date: closing_date,
        payment_status: payment_status,
        loan_status: loan_status,
        request_status: request_status,
        loan_amount: loan_amount,
      };
      // console.log("postData", postData);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      };
      axios
        .post(apiUrl, postData, { headers })
        .then((response) => {
          if (response.data.status) {
            toast.success(response.data.message);
            setIsLoading(false);
            getOpenLoanList();
            getRolloverList();
            getCloseLoanList();
            getDueLoanList();
            getPendingLoanList();
            getApprovedReqList();
            getPendingLoanList();
            getCanceledReqList();
            setModalOpen(false);
          } else {
            toast.error(response.data.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("updated Error: ", error);
        });
    }
  };

  // console.log(selected1);

  return (
    <form onSubmit={handleUpdateLoan}>
      <div className="mb-[30px] mt-[25px] grid w-full grid-cols-1 gap-x-5 gap-y-5 md:grid-cols-2">
        {requestStatus && (
          <div className="w-full">
            <h3 className="font-inter  text-[17px] font-medium text-black  ">
              Request Status
            </h3>

            {/* Selection Item 1 in Modal */}
            <Listbox value={selected3} onChange={setSelected3}>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-pointer rounded-lg border border-secondBlack bg-white px-2 py-[6px] text-left font-inter  text-[17px] font-normal text-secondBlack focus:text-secondBlack focus:outline-none  ">
                  <span className="block truncate capitalize">
                    {selected3 ? (
                      selected3.name
                    ) : (
                      <span className="capitalize">{data?.request_status}</span>
                    )}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <BsChevronDown
                      className="-mr-1 ml-2 h-5 w-5 text-secondBlack hover:text-black"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-[10000] mt-2 w-full overflow-auto rounded-lg border bg-white px-1 py-[5px] text-[17px]  capitalize shadow-selectShadow  ">
                    {selectOptions3.map((singleOption, singleOptionIdx) => (
                      <Listbox.Option
                        key={singleOptionIdx}
                        className={({ active }) =>
                          `relative m-2 cursor-pointer rounded-lg border border-gray-200 py-2 pl-2  text-[17px]   ${
                            active
                              ? "bg-gray-100 text-[#0EAB8B]"
                              : "text-secondBlack"
                          }`
                        }
                        defaultValue={data?.request_status}
                        value={singleOption}
                      >
                        {singleOption.name}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        )}
        {closingDate && (
          <div className="form-control">
            <label
              htmlFor="#"
              className="font-inter  text-[17px] font-medium text-black  "
            >
              Closing Date
            </label>
            <input
              type="date"
              name="close_date"
              className="input input-bordered mt-1 min-h-[42px] w-full rounded-md   border   border-secondBlack bg-white px-2 text-left font-inter  text-[17px] font-normal text-secondBlack focus:text-secondBlack focus:outline-none"
              defaultValue={data?.closing_date}
            />
          </div>
        )}
        {dueAmount && (
          <div className="form-control">
            <label
              htmlFor="#"
              className="font-inter  text-[17px] font-medium text-black  "
            >
              Due Amount
            </label>
            <input
              type="text"
              placeholder="Due Amount"
              name="due_amount"
              className="input input-bordered mt-1 min-h-[42px] w-full rounded-md   border   border-secondBlack bg-white px-2 text-left font-inter  text-[17px] font-normal text-secondBlack focus:text-secondBlack focus:outline-none"
              defaultValue={data?.due_amount}
            />
          </div>
        )}
        {loanAmount && (
          <div className="w-full">
            <h3 className="font-inter  text-[17px] font-medium text-black  ">
              Loan Amount
            </h3>

            {/* Selection Item 1 in Modal */}
            <Listbox value={selected4} onChange={setSelected4}>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-pointer rounded-lg border border-secondBlack bg-white px-2 py-[6px] text-left font-inter  text-[17px] font-normal text-secondBlack focus:text-secondBlack focus:outline-none  ">
                  <span className="block truncate">
                    {selected4 ? (
                      selected4.name
                    ) : (
                      <span className="capitalize">${data?.loan_amount}</span>
                    )}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <BsChevronDown
                      className="-mr-1 ml-2 h-5 w-5 text-secondBlack hover:text-black"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-[10000] mt-2 w-full overflow-auto rounded-lg border bg-white px-1 py-[5px]  text-[17px] shadow-selectShadow  ">
                    {selectOptions4.map((singleOption, singleOptionIdx) => (
                      <Listbox.Option
                        key={singleOptionIdx}
                        className={({ active }) =>
                          `relative m-2 cursor-pointer rounded-lg border border-gray-200 py-2 pl-2  text-[17px]   ${
                            active
                              ? "bg-gray-100 text-[#0EAB8B]"
                              : "text-secondBlack"
                          }`
                        }
                        defaultValue={data?.loan_amount}
                        value={singleOption}
                      >
                        ${singleOption.name}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        )}
        {paymentStatus && (
          <div className="w-full">
            <h3 className="font-inter  text-[17px] font-medium text-black  ">
              Payment Status
            </h3>

            {/* Selection Item 1 in Modal */}
            <Listbox value={selected1} onChange={setSelected1}>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-pointer rounded-lg border border-secondBlack bg-white px-2 py-[20px] text-left font-inter  text-[17px] font-normal text-secondBlack focus:text-secondBlack focus:outline-none  ">
                  <span className="block truncate">
                    {selected1 ? (
                      selected1.name
                    ) : (
                      <span className="capitalize">{data?.payment_status}</span>
                    )}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <BsChevronDown
                      className="-mr-1 ml-2 h-5 w-5 text-secondBlack hover:text-black"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-[10000] mt-2 w-full overflow-auto rounded-lg border bg-white px-1 py-[5px]  text-[17px] shadow-selectShadow  ">
                    {selectOptions1.map((singleOption, singleOptionIdx) => (
                      <Listbox.Option
                        key={singleOptionIdx}
                        className={({ active }) =>
                          `relative m-2 cursor-pointer rounded-lg border border-gray-200 py-2 pl-2  text-[17px]   ${
                            active
                              ? "bg-gray-100 text-[#0EAB8B]"
                              : "text-secondBlack"
                          }`
                        }
                        defaultValue={data?.paymentStatus}
                        value={singleOption}
                      >
                        {singleOption.name}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        )}

        {loanStatus && (
          <div className="w-full">
            <h3 className="font-inter  text-[17px] font-medium text-black  ">
              Loan Status
            </h3>

            {/* Selection Item 1 in Modal */}
            <Listbox value={selected2} onChange={setSelected2}>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-pointer rounded-lg border border-secondBlack bg-white px-2 py-[6px] text-left font-inter  text-[17px] font-normal text-secondBlack focus:text-secondBlack focus:outline-none  ">
                  <span className="block truncate">
                    {selected2 ? (
                      selected2.name
                    ) : (
                      <span className="capitalize">{data?.loan_status}</span>
                    )}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <BsChevronDown
                      className="-mr-1 ml-2 h-5 w-5 text-secondBlack hover:text-black"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-[10000] mt-2 w-full overflow-auto rounded-lg border bg-white px-1 py-[5px]  text-[17px] shadow-selectShadow  ">
                    {selectOptions2.map((singleOption, singleOptionIdx) => (
                      <Listbox.Option
                        key={singleOptionIdx}
                        className={({ active }) =>
                          `relative m-2 cursor-pointer rounded-lg border border-gray-200 py-2 pl-2  text-[17px]   ${
                            active
                              ? "bg-gray-100 text-[#0EAB8B]"
                              : "text-secondBlack"
                          }`
                        }
                        value={singleOption}
                      >
                        {singleOption.name}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        )}
      </div>
      <div className="">
        <div className="flex flex-col gap-3 md:flex-row md:gap-6">
          <div
            onClick={() => setModalOpen(false)}
            className="modal-action m-0 justify-center bg-[#EF4E57] md:justify-normal"
          >
            <button className="lg: px-[30px] py-[5px] font-poppins text-[16px] text-[17px] font-medium leading-8 text-white">
              Cancel
            </button>
          </div>
          <button
            type="submit"
            className="flex justify-center  bg-[#0EAB8B] md:justify-normal"
          >
            <span className="lg: px-[30px] py-[5px] font-poppins text-[16px] text-[17px] font-medium leading-8 text-white">
              {isLoading ? "Updating..." : "Update"}
            </span>
          </button>
        </div>
      </div>
    </form>
  );
};

export default LoanContent;
