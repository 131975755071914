import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuItems from "./MenuItems";
import HotToaster from "../Shared/HotToaster";
import axios from "axios";
import { ENDPOINT } from "../../App/config/endpoint";
import { toast } from "react-hot-toast";
import { IoNotificationsOutline } from "react-icons/io5";
import {
  CollectionSystemIcon,
  CollectionSystemMobileIcon,
} from "../../Assets/userProfile/userProfile";
import CommonNavBar from "../AdminPannel/CommonNavBar";
import { LogoutIcon } from "../../Assets/locales/SideNavigationIcons";

export default function PrimaryLayout({
  children,
  pageTitle,
  calender,
  backBtn,
}) {
  const [show, setShow] = useState(false);
  const [profile, setProfile] = useState(false);
  // navfix State
  const [navfix, setNavfix] = useState(false);
  const authInfo = JSON.parse(localStorage.getItem("authInfo"));
  const navigate = useNavigate();

  // navfix
  function setFixed() {
    setNavfix(true);
  }
  useEffect(() => {
    window.addEventListener("scroll", setFixed);
  }, []);

  const handleLogout = () => {
    // const token = JSON.parse(localStorage.getItem("authInfo"));
    localStorage.removeItem("authInfo");
    navigate("/admin-login", { replace: true });
    // axios
    //   .post(`${ENDPOINT.logout.userLogout}`, {
    //     headers: {
    //       Authorization: "token " + token.token,
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     if (res.data.type === "error") {
    //       toast.error(res.data.msg);
    //     } else {
    //       console.log("Res", res.data);
    //       localStorage.removeItem("authInfo");
    //       navigate("/admin-login", { replace: true });
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //     // UnAuth(e);
    //   });
  };
  return (
    <Fragment>
      <HotToaster />
      <div className="min-h-screen w-full bg-black">
        <div className="flex-no-wrap flex min-h-screen">
          {/* desktop Sidebar starts */}
          <div className="lg:!min-w-[24%] xl:!min-w-[18%] 2xl:!min-w-[14%]">
            <div
              className={`${
                navfix
                  ? "!fixed top-0 z-10 !min-w-full lg:left-0 lg:!min-w-[22%] xl:!min-w-[18%] 2xl:!min-w-[15%]"
                  : "absolute top-0 z-10 !min-w-full lg:left-0 lg:!min-w-[22%] xl:!min-w-[18%] 2xl:!min-w-[15%]"
              }`}
            >
              <div className="absolute left-0 top-0 hidden min-h-[100%] bg-black lg:relative lg:block">
                <div className="h-13 flex w-full items-center px-8 py-4 pb-[2rem] pt-8">
                  <Link
                    to="/"
                    className="-mt-[6px] flex cursor-pointer items-center gap-[8px] text-2xl font-bold text-white"
                  >
                    <span>
                      <CollectionSystemIcon
                        mainWidth={"35px"}
                        mainHeight={"35px"}
                      />
                    </span>
                    <span>Quick Cash</span>
                  </Link>
                </div>
                <div
                  className={` min-h-screen overflow-y-scroll px-6 pb-20 ${
                    navfix ? "scrollbar-thin scrollbar-thumb-gray-700" : ""
                  }`}
                  style={{
                    maxHeight: navfix ? "100vh" : "unset",
                  }}
                >
                  <MenuItems />
                  <div className="rounded-rtext-sm mb-[5px] list-none leading-3 tracking-normal text-white focus:outline-none">
                    <div
                      onClick={() => handleLogout()}
                      className={`group flex w-full cursor-pointer items-center rounded px-[16px] py-[13px] text-white transition-all hover:bg-[#ffffff1a] focus:bg-[#ffffff1a] active:bg-[#ffffff1a]`}
                    >
                      <div className="flex items-center">
                        <LogoutIcon />
                        <span className="pl-[11px] font-inter text-[14px] font-medium text-white focus:bg-blue-500 active:text-white">
                          Logout
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Mobile responsive sidebar*/}
          <div
            className={
              show
                ? "fixed z-40 min-h-screen w-full  translate-x-0  transform "
                : "absolute z-40 min-h-screen w-full  -translate-x-full transform"
            }
            id="mobile-nav"
          >
            <div
              className="absolute h-full w-full bg-gray-800 opacity-50 lg:hidden"
              onClick={() => setShow(!show)}
            />
            <div
              className={`relative z-40 min-h-screen ${
                show && "max-h-screen overflow-y-scroll"
              } w-64 bg-black pb-10 shadow transition duration-150 ease-in-out md:w-96 lg:hidden`}
            >
              <div className="flex h-full w-full flex-col justify-between">
                <div>
                  <div className="flex items-center justify-between px-[20px] pt-[18px] md:px-[32px]">
                    <Link
                      to="/"
                      className="flex cursor-pointer items-center justify-between gap-2"
                    >
                      <CollectionSystemMobileIcon />
                      <span className="text-[20px] font-semibold text-white">
                        Quick Cash
                      </span>
                    </Link>
                    <div
                      id="closeSideBar"
                      className="flex h-10 w-10 z-[99999] cursor-pointer items-center justify-center"
                      onClick={() => setShow(!show)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-x text-white"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <line x1={18} y1={6} x2={6} y2={18} />
                        <line x1={6} y1={6} x2={18} y2={18} />
                      </svg>
                    </div>
                  </div>
                  <div className="p-6">
                    <MenuItems />
                  </div>
                </div>
                <div className="w-full">
                  <div className="mb-4 flex w-full justify-center px-6">
                    <div className="relative w-full">
                      <div className="absolute inset-0 m-auto ml-4 h-4 w-4 text-gray-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-search"
                          width={19}
                          height={19}
                          viewBox="0 0 24 24"
                          strokeWidth={1}
                          stroke="#222"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <circle cx={10} cy={10} r={7} />
                          <line x1={21} y1={21} x2={15} y2={15} />
                        </svg>
                      </div>
                      <input
                        className="text-md w-full rounded bg-white py-3 pl-10 text-black focus:outline-none"
                        type="text"
                        placeholder="Search here"
                      />
                    </div>
                  </div>
                  <div className="border-t border-gray-300">
                    <div className="mx-6 mt-5 flex cursor-pointer items-center gap-1 rounded-[4px] px-[13px] py-[13px] text-white transition-all hover:bg-[#ffffff1a] focus:bg-[#ffffff1a] active:bg-[#ffffff1a]">
                      <div className="relative flex cursor-pointer text-[24px]">
                        <IoNotificationsOutline className="text-white" />
                        <div className="badge badge-lg absolute -top-1 right-0 h-[14px] w-[14px] rounded-full bg-[#FF7800] p-1 text-white">
                          <span className="absolute -top-[2.5px] left-[3.4px] font-poppins text-[12px]">
                            0
                          </span>
                        </div>
                      </div>

                      <p>Notification</p>
                    </div>
                    <div
                      className={`flex w-full items-center justify-between p-6 pb-0 pt-5 ${
                        profile && "mb-[125px]"
                      }`}
                    >
                      <div
                        className="relative flex w-full cursor-pointer items-center"
                        onClick={() => setProfile(!profile)}
                      >
                        <div className="rounded-full">
                          {profile ? (
                            <ul className="absolute  left-0 mt-12 w-full rounded p-2 shadow-selectShadow sm:mt-16">
                              
                              <li className="flex w-full cursor-pointer items-center justify-between rounded px-[16px] py-[12px] text-white transition-all hover:bg-[#ffffff1a] focus:bg-[#ffffff1a] active:bg-[#ffffff1a]">
                                <div className="flex items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-logout"
                                    width={20}
                                    height={20}
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                                    <path d="M7 12h14l-3 -3m0 6l3 -3" />
                                  </svg>
                                  <span
                                    onClick={() => handleLogout()}
                                    className="pl-[11px] font-inter text-[14px] font-medium text-white focus:bg-blue-500 active:text-white"
                                  >
                                    Sign out
                                  </span>
                                </div>
                              </li>
                            </ul>
                          ) : (
                            ""
                          )}

                          <div className="relative">
                            <img
                              className="h-10 w-10 rounded-md object-cover"
                              src="https://i.ibb.co/zXw7H0W/360-F-107434511-iar-F2z88c6-Ds6-Algtwot-HSAkt-WCd-YOn7.jpg"
                              alt={authInfo?.user?.username}
                            />
                            {/* <div className="absolute inset-0 m-auto mb-0 mr-0 h-2 w-2 rounded-full border border-white bg-green-400" /> */}
                          </div>
                        </div>
                        <div className="ml-3 mr-6">
                          <p className="font-poppins text-base font-medium capitalize text-white">
                            {authInfo?.user?.username}
                          </p>
                          <p className="font-regular font-poppins text-sm text-[#a4a7bb]">
                            Admin
                          </p>
                        </div>
                        <div className="ml-auto cursor-pointer text-gray-600">
                          <svg
                            aria-haspopup="true"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-chevron-down"
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="white"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <polyline points="6 9 12 15 18 9" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Mobile responsive sidebar*/}
          {/* Sidebar ends */}
          <div className="w-full bg-white lg:!min-w-[75%] xl:!min-w-[81%] 2xl:!min-w-[85%]">
            {/* Navigation starts */}
            <div
              className={`${
                navfix
                  ? "!fixed z-[100] !min-w-full lg:right-0 lg:!min-w-[78%] xl:!min-w-[82%] 2xl:!min-w-[85%]"
                  : "absolute top-0 z-10 !min-w-full lg:right-0 lg:!min-w-[78%] xl:!min-w-[82%] 2xl:!min-w-[85%]"
              }`}
            >
              <nav
                className={`h-21 z-[100] flex items-center justify-end bg-black pb-1 lg:h-24 lg:items-stretch lg:justify-between`}
              >
                <div className="hidden w-full items-center bg-black px-8 lg:flex xl:px-10">
                  <div className="hidden h-full w-[60%] items-center lg:flex xl:w-[80%]">
                    <div className="relative w-full">
                      <div
                        className="absolute inset-0 m-auto mb-3 ml-4
                       h-[23px] w-4 text-black"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M10.9338 19.4671C15.6466 19.4671 19.4671 15.6466 19.4671 10.9338C19.4671 6.22091 15.6466 2.40039 10.9338 2.40039C6.22091 2.40039 2.40039 6.22091 2.40039 10.9338C2.40039 15.6466 6.22091 19.4671 10.9338 19.4671Z"
                            stroke="#333333"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21.6 21.601L16.96 16.9609"
                            stroke="#333333"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <input
                        className="w-full rounded-lg bg-white px-11 py-[13px] font-inter text-[16px] font-normal text-secondBlack focus:outline-none"
                        type="text"
                        placeholder="Search here"
                        // onChange={}
                      />
                    </div>
                  </div>
                  <div className="hidden w-[40%] lg:flex">
                    <div className="flex w-full items-center justify-end gap-5">
                      <div className="relative cursor-pointer text-[24px]">
                        <IoNotificationsOutline className="text-white" />
                        <div className="badge badge-lg absolute -top-1 right-0 h-[14px] w-[14px] rounded-full bg-[#FF7800] p-1 text-white">
                          <span className="absolute -top-[2.5px] left-[3.4px] font-poppins text-[12px]">
                            0
                          </span>
                        </div>
                      </div>
                      <div
                        className="relative flex cursor-pointer items-center"
                        onClick={() => setProfile(!profile)}
                      >
                        <div className="rounded-full">
                          {profile ? (
                            <ul className="absolute  right-[10px] mt-[50px] w-[200px] rounded bg-white shadow-selectShadow">
                              
                              <li
                                className=" flex w-full cursor-pointer items-center justify-between rounded px-[10px] py-[10px] text-black transition-all hover:bg-[#e3e1e1] focus:bg-[#e3e1e1] active:bg-[#e3e1e1]"
                                onClick={() => handleLogout()}
                              >
                                <div className="flex items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-logout"
                                    width={20}
                                    height={20}
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="#222"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                                    <path d="M7 12h14l-3 -3m0 6l3 -3" />
                                  </svg>
                                  <span className="pl-[11px] font-inter text-[14px] font-medium text-black focus:bg-blue-500 active:text-black">
                                    Sign out
                                  </span>
                                </div>
                              </li>
                            </ul>
                          ) : (
                            ""
                          )}
                          <div className="relative">
                            <div className="flex items-center justify-center gap-5">
                              <img
                                className="h-10 w-10 rounded-full object-cover"
                                src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                                alt="avatar"
                              />
                              {/* <div className="absolute inset-0 m-auto mb-0 mr-0 h-2 w-2 rounded-full border border-white bg-green-400" /> */}
                            </div>
                          </div>
                        </div>
                        <div className="ml-3 mr-6">
                          <p className="mb-1 font-inter text-[18px] font-semibold text-white">
                            {authInfo?.user?.username}
                          </p>
                          <p className="font-inter text-sm font-normal text-white">
                            Admin
                          </p>
                        </div>
                        <div className="cursor-pointer text-gray-600">
                          <svg
                            aria-haspopup="true"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-chevron-down"
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#fff"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <polyline points="6 9 12 15 18 9" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`visible ${
                    show && "pb-[23px]"
                  } relative ml-2 flex w-full items-center justify-between px-[12px] py-[18px] text-gray-600 md:px-[24px] lg:hidden`}
                >
                  <div className="flex w-fit items-center gap-2">
                    <div>
                      <CollectionSystemIcon
                        mainWidth={"35px"}
                        mainHeight={"35px"}
                      />
                    </div>
                    <h1 className="text-[20px] font-semibold text-white">
                      Quick Cash
                    </h1>
                  </div>
                  <div
                    className="mr-2 cursor-pointer text-gray-600 lg:hidden"
                    onClick={() => setShow(!show)}
                  >
                    {show ? (
                      ""
                    ) : (
                      <div className="flex items-center justify-between">
                        <svg
                          aria-label="Main Menu"
                          aria-haspopup="true"
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-menu cursor-pointer"
                          width={30}
                          height={40}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="white"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <line x1={4} y1={8} x2={20} y2={8} />
                          <line x1={4} y1={16} x2={20} y2={16} />
                          <line x1={4} y1={24} x2={20} y2={24} />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </nav>
            </div>
            {/* Navigation ends */}

            <div className="mx-auto bg-white">
              <div className="bg-white">
                <div className="mx-auto px-[15px] pb-[10px] pt-[105px] lg:px-[30px] 2xl:pl-[50px]">
                  <CommonNavBar
                    pageTitle={pageTitle}
                    calender={calender}
                    backBtn={backBtn}
                  />
                </div>
              </div>
              <div className="mx-auto bg-white 2xl:pl-[20px]">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
