import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { ENDPOINT } from "../App/config/endpoint";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext(null);
const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allProduct, setAllProduct] = useState([]);
  const [loanList, setLoanList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [agentDetails, setAgentDetails] = useState({});
  const [paymentStatus, setPaymentStatus] = useState("");
  const [loanStatus, setLoanStatus] = useState("");
  const [requestStatus, setRequestStatus] = useState("");
  const [formDate, setFormDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dashboardData, setDashboardData] = useState({});
  const [userList, setUserList] = useState([]);
  const [userListLoading, setUserListLoading] = useState(false);

  const token = JSON.parse(localStorage.getItem("authInfo"));
  // const navigate = useNavigate();
  // Pagination state
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
  });

  const changePage = (newPage) => {
    console.log(newPage);
    if (newPage >= 1 && newPage <= pagination?.totalPages) {
      updatePagination(newPage, pagination?.totalPages);
    }
  };

  // Open User List
  const updatePagination = (currentPage, totalPages) => {
    setPagination((prev) => ({
      ...prev,
      currentPage,
      totalPages,
    }));
  };
  // Dashboard Data
  const getDashboardData = () => {
    if (!token) {
      return;
    } else {
      const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.dashboard.dashboardData}`;
      const postData = {};
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.token}`,
      };
      axios
        .post(apiUrl, postData, { headers })
        .then((response) => {
          let allData = response?.data?.analytics;
          setDashboardData(allData);
          // console.log("dashboard data", allData);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.error); // If token is expired then show the toast
          localStorage.removeItem("authInfo");
          window.location.href = "/admin-login";
        });
    }
  };

  //   Product List
  const getProductList = () => {
    if (!token) {
      return;
    } else {
      const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.product.productList}`;
      const postData = {};
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.token}`,
      };
      axios
        .post(apiUrl, postData, { headers })
        .then((response) => {
          let allData = response?.data?.data;
          setAllProduct(allData);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  //   User List
  const getLoanList = () => {
    if (!token) {
      return;
    } else {
      const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.loanList}`;
      const postData = {
        perpage: "10",
        page: "1",
        payment_status: "",
        loan_status: "",
        request_status: "",
        from_date: "",
        to_date: "",
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.token}`,
      };

      axios
        .post(apiUrl, postData, { headers })
        .then((res) => {
          const loan_list = res?.data?.data;
          setLoanList(loan_list);
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    }
  };

  // Agent List
  const getAgentList = () => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.agent.agentList}`;
    const postData = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.token}`,
    };
    if (!token) {
      return;
    } else {
      axios
        .post(apiUrl, postData, { headers })
        .then((response) => {
          const allAgent = response?.data?.data;
          setAgentList(allAgent);
        })
        .catch((er) => {
          console.error(er);
        });
    }
  };

  // Agent Profile Details
  const getAgentDetails = (customerId) => {
    if (!token) {
      return;
    } else {
      const postData = {
        agent_id: customerId,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      };
      const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.agent.agentDetails}`;

      axios
        .post(apiUrl, postData, { headers })
        .then((res) => {
          setAgentDetails(res?.data?.data);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  // Customer List
  const getAllUserList = () => {
    setUserListLoading(true);
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.user.userList}`;
    const postData = {
      perpage: pagination?.itemsPerPage.toString(),
      page: pagination?.currentPage.toString(),
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.token}`,
    };
    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        setUserList(res?.data?.data);
        updatePagination(pagination?.currentPage, res?.data?.totalPage || 1);
        setUserListLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setUserListLoading(false);
      });
  };

  const blockUser = (id) => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.user.blockUser}`;
    const postData = {
      user_id: id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        const status = res.status;
        if (status === 200) {
          toast.success(res.data?.message);
        } else {
          toast.error("Something wrong, please try again.");
        }
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
        toast.error("Network error.");
      });
  };

  const unblockUser = (id) => {
    return toast.error("Something wrong!");
  };

  //   For Call Function
  useEffect(() => {
    getProductList();
    getLoanList();
    getAgentList();
    getAllUserList();
    getDashboardData();
  }, [pagination.currentPage]);

  const authInfo = {
    userListLoading,
    isLoading,
    setIsLoading,
    allProduct,
    getProductList,
    agentList,
    getAgentList,
    getAgentDetails,
    agentDetails,
    getLoanList,
    loanList,
    setPaymentStatus,
    setLoanStatus,
    setRequestStatus,
    setFormDate,
    setToDate,
    token,
    setUserList,
    userList,
    getAllUserList,
    blockUser,
    unblockUser,
    dashboardData,
    pagination,
    setPagination,
    changePage,
  };
  return (
    <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
