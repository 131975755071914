import { IoArrowBackCircleSharp } from "react-icons/io5";

const BackBtn = () => {
  const goBackToPreviousLocation = () => {
    window.history.back();
  };
  return (
    <div>
      <button onClick={goBackToPreviousLocation} className="flex btn gap-1 bg-black px-[10px] font-poppins items-center py-[8px] rounded-md justify-center text-[18px] text-white hover:bg-secondBlack hover:text-white">
        <span className="text-white">
          <IoArrowBackCircleSharp size={24} />
        </span>
        Back
      </button>
    </div>
  );
};

export default BackBtn;
