import React, { useState, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { BsChevronDown, BsCalendar} from "react-icons/bs";

const LifeTime = () => {
      const selectOptions2 = [
        { name: "Daily " },
        { name: "Weekly" },
        { name: "Monthly" },
        { name: "Yearly" },
        { name: "Life time" },
      ];
    
      const [selected2, setSelected2] = useState(() => selectOptions2[0]);
    
  return (
    <div>
      <div className="w-full lg:w-[145px]">
          <Listbox value={selected2} onChange={setSelected2}>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-pointer rounded-lg border bg-white pl-2 pr-8 md:pr-10 lg:px-3 py-[8px] text-left font-inter text-[16px] font-medium text-black focus:text-secondBlack focus:outline-none lg:text-[16px] flex items-center">
                <span className="mr-2"><BsCalendar /></span>
                <span className="block truncate">{selected2.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <BsChevronDown
                    className="-mr-1 md:mr-2 h-5 w-5 text-black hover:text-violet-100"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-2 z-10 w-full overflow-auto rounded-lg bg-white flex flex-col gap-3 py-2 px-2 text-[18px] shadow-selectShadow md:text-[21px] lg:text-[24px]">
                  {selectOptions2.map((singleOption, singleOptionIdx) => (
                    <Listbox.Option
                      key={singleOptionIdx}
                      className={({ active }) =>
                        `relative cursor-pointer rounded-lg px-2 py-1 text-left font-inter text-[14px] font-medium text-black focus:text-secondBlack focus:outline-none lg:text-[16px] ${
                          active
                            ? "bg-gray-100 text-[#0EAB8B]"
                            : "text-secondBlack"
                        }`
                      }
                      value={singleOption}
                    >
                      {({ selected2 }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected2 ? "font-normal" : "font-normal"
                            }`}
                          >
                            {singleOption.name}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>
  );
};

export default LifeTime;
