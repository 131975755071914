import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { AcIcon } from "../../Assets/icons";
import { ENDPOINT } from "../../App/config/endpoint";
import axios from "axios";
import { AuthContext } from "../../Providers/AuthProvider";

const Transaction = ({ data, pathname }) => {
  const { token } = useContext(AuthContext);
  const userId = data?.user_id;
  const [isLoading, setIsLoading] = useState(false);
  const [rolloverData, setRolloverData] = useState([]);
  // console.log(rolloverData);
  const [repaymentData, setRepaymentData] = useState([]);

  const rolloverApiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.rolloverTransaction}`;
  const repaymentApiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.loan.repaymentTransaction}`;

  const postData = {
    user_id: userId,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token?.token}`,
  };

  const getRolloverList = () => {
    setIsLoading(true);
    axios
      .post(rolloverApiUrl, postData, { headers })
      .then((res) => {
        if (res.data.status) {
          setRolloverData(res.data.data);
        }
      })
      .catch((err) => {
        // console.log("rollover er: ", err);
      })
      .finally(() => {
        setIsLoading(false); // This ensures isLoading is set to false after the operation is complete
      });
  };

  const getRepaymentList = () => {
    setIsLoading(true);
    axios
      .post(repaymentApiUrl, postData, { headers })
      .then((res) => {
        console.log(res);
        if (res.data.status === true) {
          setRepaymentData(res?.data?.data);
        }
      })
      .catch((err) => {
        // console.log("repayment er: ", err);
      })
      .finally(() => {
        setIsLoading(false); // This ensures isLoading is set to false after the operation is complete
      });
  };

  useEffect(() => {
    getRolloverList();
    getRepaymentList();
  }, [userId]);

  console.log(repaymentData);

  return isLoading ? (
    <div className="ml-[-100px] mt-[100px] flex items-center justify-center">
      <span className="auto_loader"></span>
    </div>
  ) : rolloverData.length <= 0 && repaymentData.length <= 0 ? (
    <div className="mt-[100px] flex h-full w-full items-center justify-center">
      <span className="text-center font-poppins text-base text-gray-400">
        No Data Available
      </span>
    </div>
  ) : (
    <div>
      {rolloverData?.length <= 0 ? (
        <div className="mt-[50px] flex h-full w-full items-center justify-center">
          <span className="text-center font-poppins text-base text-gray-400">
            No Rollover Data Available
          </span>
        </div>
      ) : (
        <div>
          <h1 className="font-poppins text-[20px] font-medium text-secondBlack">
            Rollover Transaction
          </h1>
          {rolloverData?.map((data, i) => {
            return (
              <div className="mx-auto mb-[6px] grid select-none divide-y divide-neutral-200  rounded-lg border shadow-sm">
                <div className="">
                  <details className="group">
                    <summary className="flex cursor-pointer  list-none items-center justify-between rounded-t-lg bg-[#f1f0f0] px-[10px] py-[7px] font-medium text-black">
                      <span>Transaction No: {i + 1}</span>
                      <span className="transition group-open:rotate-180">
                        <AcIcon />
                      </span>
                    </summary>

                    <div className="group-open:animate-fadeIn mb-3 mt-1 px-3">
                      <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
                        <div className="">
                          <h3 className="font-poppins text-[14px] font-medium capitalize  leading-[29.05px] text-secondBlack">
                            Amount
                          </h3>
                          <div className="flex  min-h-[40px] items-center rounded-lg border px-[10px] font-poppins text-[14px] font-normal  capitalize leading-[29.05px] text-secondBlack shadow-sm">
                            ₹{data?.amount || <span className="">0</span>}
                          </div>
                        </div>
                        <div>
                          <h3 className="font-poppins text-[14px] font-medium capitalize  leading-[29.05px] text-secondBlack">
                            UTR Number
                          </h3>
                          <div className="flex  min-h-[40px] items-center rounded-lg border px-[10px] font-poppins text-[14px] font-normal  capitalize leading-[29.05px] text-secondBlack shadow-sm">
                            {data?.utr || (
                              <span className="text-gray-400">N/A</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-1">
                        <h3 className="font-poppins text-[14px] font-medium capitalize  leading-[29.05px] text-secondBlack">
                          Voucher
                        </h3>
                        <img
                          src={data?.voucher_img}
                          alt="voucher"
                          className=" h-[200px] w-[200px] rounded-xl border shadow-sm"
                        />
                      </div>
                    </div>
                  </details>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {repaymentData?.length <= 0 ? (
        <div className="mt-[50px] flex h-full w-full items-center justify-center">
          <span className="text-center font-poppins text-base text-gray-400">
            No Repayment Data Available
          </span>
        </div>
      ) : (
        <div className="mt-3">
          <h1 className="font-poppins text-[20px] font-medium text-secondBlack">
            Repayment Transaction
          </h1>
          {repaymentData?.map((data, i) => {
            return (
              <div className="mx-auto mb-[6px] grid select-none divide-y divide-neutral-200 rounded-lg border shadow-sm">
                <div className="">
                  <details className="group">
                    <summary className="flex cursor-pointer  list-none items-center justify-between rounded-t-lg bg-[#f1f0f0] px-[10px] py-[7px] font-medium text-black">
                      <span>Transaction No: {i + 1}</span>
                      <span className="transition group-open:rotate-180">
                        <AcIcon />
                      </span>
                    </summary>

                    <div className="group-open:animate-fadeIn mb-3 mt-2 px-3">
                      <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
                        <div className="">
                          <h3 className="font-poppins text-[14px] font-medium capitalize  leading-[29.05px] text-secondBlack">
                            Amount
                          </h3>
                          <div className="flex  min-h-[40px] items-center rounded-lg border px-[10px] font-poppins text-[14px] font-normal  capitalize leading-[29.05px] text-secondBlack shadow-sm">
                            ₹{data?.amount || <span className="">0</span>}
                          </div>
                        </div>
                        <div className="">
                          <h3 className="font-poppins text-[14px] font-medium capitalize  leading-[29.05px] text-secondBlack">
                            UTR Number
                          </h3>
                          <div className="flex  min-h-[40px] items-center rounded-lg border px-[10px] font-poppins text-[14px] font-normal  capitalize leading-[29.05px] text-secondBlack shadow-sm">
                            {data?.utr || (
                              <span className="text-gray-400">N/A</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-1">
                        <h3 className="font-poppins text-[14px] font-medium capitalize  leading-[29.05px] text-secondBlack">
                          Voucher
                        </h3>
                        <img
                          src={data?.voucher_img}
                          alt="voucher"
                          className=" h-[200px] w-[200px] rounded-xl border shadow-sm"
                        />
                      </div>
                    </div>
                  </details>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Transaction;
