import React from "react";
import { PdfIcon } from "../../Assets/userProfile/userProfile";

const CustomerInformationContent = ({data}) => {
  // console.log('customer: ', data);
  return (
    <div>
      {/* Top Nav */}
      <div className="flex items-center justify-between">
        <h1 className="font-inter text-[20px] mt-5 md:text-[24px] py-8 pb-[10px] md:pb-[20px] lg:py-8 font-semibold leading-[44.8px] text-secondBlack">
          Customers Information
        </h1>
      </div>

      <div className="grid grid-cols-1 gap-[15px] pb-[10px] md:pb-[20px] lg:pb-8 md:grid-cols-2">
        <div>
          <h3 className="capitalize font-inter text-[16px] md:text-[20px] font-semibold leading-[29.05px] text-secondBlack">
            Full Name
          </h3>
          <div className=" border flex min-h-[50px] items-center rounded-lg px-[16px] font-inter text-[18px] font-normal capitalize leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4">
            {data?.full_name ? data?.full_name : <span className="text-[#826f6fb9]">N/A</span>}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[16px] md:text-[20px] font-semibold leading-[29.05px] text-secondBlack">
            Date Of Birth
          </h3>
          <div className="  border flex min-h-[50px] items-center rounded-lg px-[16px] font-inter text-[18px] font-normal capitalize leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4">
            {data?.birth_date ? data?.birth_date : <span className="text-[#826f6fb9]">N/A</span>}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[16px] md:text-[20px] font-semibold leading-[29.05px] text-secondBlack">
            Age
          </h3>
          <div className=" border flex min-h-[50px] items-center rounded-lg px-[16px] font-inter text-[18px] font-normal capitalize leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4">
          {data?.age ? data?.age : <span className="text-[#826f6fb9]">N/A</span>}
          </div>
        </div>
        <div>
          <h3 className="capitalize font-inter text-[16px] md:text-[20px] font-semibold leading-[29.05px] text-secondBlack">
            Gender
          </h3>
          <div className=" border flex min-h-[50px] items-center rounded-lg px-[16px] font-inter text-[18px] font-normal capitalize leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4">
            {data?.gender ? data?.gender : <span className="text-[#826f6fb9]">N/A</span>}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[16px] md:text-[20px] font-semibold leading-[29.05px] text-secondBlack">
            Blood Group
          </h3>
          <div className=" border flex min-h-[50px] items-center rounded-lg px-[16px] font-inter text-[18px] font-normal capitalize leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4">
            {data?.blood_group ? data?.blood_group : <span className="text-[#826f6fb9]">N/A</span>}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[16px] md:text-[20px] font-semibold leading-[29.05px] text-secondBlack">
            Pan Number
          </h3>
          <div className=" border flex min-h-[50px] items-center rounded-lg px-[16px] font-inter text-[18px] font-normal capitalize leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4">
          {data?.card_no ? data?.card_no : <span className="text-[#826f6fb9]">N/A</span>}
          </div>
        </div>
        <div>
          <h3 className="font-inter text-[16px] md:text-[20px] font-semibold leading-[29.05px] text-secondBlack">
            Phone Number
          </h3>
          <div className=" border flex min-h-[50px] items-center rounded-lg px-[16px] font-inter text-[18px] font-normal capitalize leading-[29.05px] text-secondBlack  shadow-smallShadow md:mt-[12px] md:text-[21px] lg:mt-4">
            {data?.mobile_code} {data?.mobile_no}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInformationContent;
