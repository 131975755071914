import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import MainItems from "./MainItems";
import { useState } from "react";

export default function Items({ data }) {
  const location = useLocation();

  return (
    <Fragment>
      <li className="rounded-rtext-sm mb-[5px] leading-3 tracking-normal text-white focus:outline-none">
        {data?.mainMenus?.map((data, j) => {
          // console.log(data.url);
          return data?.submenu?.length > 0 ? (
            <MainItems data={data} key={data.id} />
          ) : (
            <Link
              key={data.id}
              to={data.url}
              className={`group ${location.pathname === data.url && 'bg-[#ffffff1a]'} flex w-full items-center rounded px-[16px] py-[13px] text-white transition-all hover:bg-[#ffffff1a]`}
            >
              <div className="flex items-center">
                {data.icon}
                <span className="pl-[11px] font-inter text-[14px] font-medium text-white focus:bg-blue-500 active:text-white">
                  {data.name}
                </span>
              </div>
            </Link>
          );
        })}
      </li>
    </Fragment>
  );
}
