import React, { lazy, Suspense, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageNotFound from "./Pages/PageNotFound";
import GlobalLoader from "./GlobalLoader";
import PrivateRoute from "./Component/Shared/PrivateRoute";

import SentNotification from "./Pages/AdminPanel/Settings/SentNotification";
import ChangePassword from "./Pages/AdminPanel/Settings/ChangePassword";
import AuthProvider from "./Providers/AuthProvider";
import WebSupport from "./Pages/AdminPanel/Settings/WebSupport";
import Repayment from "./Pages/AdminPanel/Loan/Repayment";
const LoanReqProvider = lazy(() => import("./Providers/LoanReqProvider"));
const LoanReqProfile = lazy(() => import("./Pages/AdminPanel/LoanRequest/LoanReqProfile"));
const Login = lazy(() => import("./Pages/AdminPanel/Auth/Login"));
const RecoverAccount = lazy(() =>
  import("./Pages/AdminPanel/Auth/RecoverAccount")
);
const CreateNewPass = lazy(() =>
  import("./Pages/AdminPanel/Auth/CreateNewPass")
);
const ProductInfo = lazy(() => import("./Component/dashboard/ProductInfo"));
const EditProduct = lazy(() =>
  import("./Pages/AdminPanel/Product/EditProduct")
);
const AddProduct = lazy(() => import("./Pages/AdminPanel/Product/AddProduct"));
const AddAgent = lazy(() =>
  import("./Pages/AdminPanel/CollectionAgent/AddAgent")
);
const EditAgent = lazy(() =>
  import("./Pages/AdminPanel/CollectionAgent/EditAgent")
);
const DueLoan = lazy(() => import("./Pages/AdminPanel/Loan/DueLoan"));
const CloseLoan = lazy(() => import("./Pages/AdminPanel/Loan/CloseLoan"));
const CollectionAgentProfile = lazy(() =>
  import("./Pages/AdminPanel/CollectionAgent/CollectionAgentProfile")
);
const Rollover = lazy(() => import("./Pages/AdminPanel/Loan/Rollover"));
const LoanList = lazy(() =>
  import("./Pages/AdminPanel/CollectionAgent/LoanList")
);
const LoginHistory = lazy(() =>
  import("./Pages/AdminPanel/CollectionAgent/LoginHistory")
);
const LoanRequest = lazy(() =>
  import("./Pages/AdminPanel/LoanRequest/PendingRequest")
);
const ApprovedRequest = lazy(() =>
  import("./Pages/AdminPanel/LoanRequest/ApprovedRequest")
);
const LoanRequestDetails = lazy(() =>
  import("./Pages/AdminPanel/LoanRequest/LoanRequestDetails")
);
const LoanRequestProfile = lazy(() =>
  import("./Pages/AdminPanel/LoanRequest/LoanReqProfile")
);
const CancelRequest = lazy(() =>
  import("./Pages/AdminPanel/LoanRequest/CancelRequest")
);
const Customers = lazy(() => import("./Pages/AdminPanel/Customers/Customers"));
const Product = lazy(() => import("./Pages/AdminPanel/Product/Product"));
const OpenLoan = lazy(() => import("./Pages/AdminPanel/Loan/OpenLoan"));
const CustomerProfileDetails = lazy(() =>
  import("./Pages/AdminPanel/Customers/CustomerProfileDetails")
);
const CustomerMediaContact = lazy(() =>
  import("./Pages/AdminPanel/Customers/CustomerMediaContact")
);
const CustomerCallMessage = lazy(() =>
  import("./Pages/AdminPanel/Customers/CustomerCallMessage")
);
const CollectionAgent = lazy(() =>
  import("./Pages/AdminPanel/CollectionAgent/CollectionAgent")
);
const MyTask = lazy(() => import("./Pages/CRM/MyTask"));
const UserProfile = lazy(() => import("./Pages/CRM/UserProfile"));
const MediaContact = lazy(() => import("./Pages/CRM/MediaContact"));
const CallAndMessage = lazy(() => import("./Pages/CRM/CallAndMessage"));
const Home = lazy(() => import("./Pages/Home"));
const Table = lazy(() => import("./Component/Table/Table"));
const AppLinkComponent = lazy(() => import("./Pages/AdminPanel/AppLink/AppLink"));
const Account = lazy(() => import("./Pages/AdminPanel/Account/Account"));

export default function App() {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <AuthProvider>
      <LoanReqProvider>
        <Suspense fallback={<GlobalLoader />}>
          <BrowserRouter>
            <Routes>
              <Route path="/loading" element={<GlobalLoader />} />
              <Route
                path="/admin-login"
                element={
                  <Login isLoading={isLoading} setIsLoading={setIsLoading} />
                }
              />
              <Route
                path="/recover-account"
                element={
                  <RecoverAccount
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                }
              />
              <Route
                path="/create-new-password"
                element={
                  <CreateNewPass
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                }
              />

              <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<Home />} />
                <Route
                  path="/table"
                  element={
                    <Table isLoading={isLoading} setIsLoading={setIsLoading} />
                  }
                />
                <Route
                  path="/customers"
                  element={
                    <Customers
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />

                <Route
                  path={`/customers/profile/:id`}
                  element={
                    <CustomerProfileDetails
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path={`/:path/profile/:id/media-contact-data`}
                  element={
                    <CustomerMediaContact
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path={`/:path/profile/:id/call-message-history`}
                  element={
                    <CustomerCallMessage
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/collection-agent"
                  element={
                    <CollectionAgent
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/collection-agent/profile/:id"
                  element={
                    <CollectionAgentProfile
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/collection-agent/:id/edit-agent-details"
                  element={
                    <EditAgent
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/collection-agent/profile/:id/loan-list"
                  element={
                    <LoanList
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/collection-agent/profile/:id/login-history"
                  element={
                    <LoginHistory
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/collection-agent/create-new-agent"
                  element={
                    <AddAgent
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/product"
                  element={
                    <Product
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/link"
                  element={
                    <AppLinkComponent
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/setting/account"
                  element={
                    <Account
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/product/edit/:id"
                  element={
                    <EditProduct
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/product/add-new-product"
                  element={
                    <AddProduct
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/loan/open-loan"
                  element={
                    <OpenLoan
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/loan/due-loan"
                  element={
                    <DueLoan
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/loan/close-loan"
                  element={
                    <CloseLoan
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/loan/rollover"
                  element={
                    <Rollover
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/loan/repayment"
                  element={
                    <Repayment
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/loan-request/pending-request"
                  element={
                    <LoanRequest
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path={`/loan-request/pending-request/profile/:id`}
                  element={
                    <LoanReqProfile
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path={`/loan-request/approved-request/profile/:id`}
                  element={
                    <LoanReqProfile
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path={`/loan-request/approved-request/profile/:id`}
                  element={
                    <LoanReqProfile
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path={`/loan-request/canceled-request/profile/:id`}
                  element={
                    <LoanReqProfile
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path={`/:path/:path/:id/loan-request-details`}
                  element={
                    <LoanRequestDetails
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path={`/:path/:path/profile/:id/login-history`}
                  element={
                    <LoginHistory
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/loan-request/approved-request"
                  element={
                    <ApprovedRequest
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/loan-request/canceled-request"
                  element={
                    <CancelRequest
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/setting/sent-notification"
                  element={
                    <SentNotification
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/setting/web-support"
                  element={
                    <WebSupport
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/setting/change-password"
                  element={
                    <ChangePassword
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
              </Route>
              <Route path="/">
                <Route
                  path="/crm/user-profile"
                  element={
                    <UserProfile
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  }
                />
                <Route
                  path="/crm/my-task"
                  element={
                    <MyTask
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    ></MyTask>
                  }
                />
                <Route
                  path="/crm/user-profile/media-contact"
                  element={
                    <MediaContact>
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    </MediaContact>
                  }
                />
                <Route
                  path="/crm/user-profile/call-message-history"
                  element={
                    <CallAndMessage>
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    </CallAndMessage>
                  }
                />
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </LoanReqProvider>
    </AuthProvider>
  );
}
