import React from "react";
import PrimaryLayout from "../../../Component/layouts/PrimaryLayout";
import { toast } from "react-hot-toast";
import { ENDPOINT } from "../../../App/config/endpoint";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  
  const [isShowPass, setIsShowPass] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleChangePassword = (e) => {
    setIsLoading(true);
    e.preventDefault();

    const form = e.target;

    const newPass = form.new_password.value;
    const confirmPass = form.confirm_password.value;

    if (newPass === confirmPass && newPass !== "" && confirmPass !== "") {
      const token = JSON.parse(localStorage.getItem("authInfo"));
      const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.auth.changePassword}`;
      const postData = {
        password: confirmPass,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      };

      if (!token.token) {
        form.reset();
        setIsLoading(false);
        return;
      } else {
        axios
          .post(apiUrl, postData, { headers })
          .then((res) => {
            if (res.data.type === "error") {
              // console.log("Password changes but error", res);
              toast.error(res.data.message);
              setIsLoading(false);
              form.reset();
            } else {
              toast.success(res.data.message);
              form.reset();
              setIsLoading(false);
            }
          })
          .catch((e) => {
            // console.log("e", e);
            toast.error("Incorrect Username & Password");
            // UnAuth(e);
            form.reset();
            setIsLoading(false);
          });
      }
    } else if(newPass === "" || confirmPass === ""){
      setError('Please type your password!')
      setIsLoading(false);
    } else {
      setError('Passwords do not match!')
      setIsLoading(false);
    }
  };
  return (
    <PrimaryLayout pageTitle="Change Password">
      <div className="bg-[#F0F2F5] p-[30px] min-h-screen">
        <div className="mx-auto rounded-lg bg-[#fff] p-[40px]">
          <form onSubmit={handleChangePassword}>
            <div className="mt-5 w-full relative">
              <h3 className="font-poppins text-[18px] font-medium text-black ">
                New Password
              </h3>
              <input
                type={isShowPass ? 'text' : 'password'}
                onChange={()=> setError('')}
                className="w-full rounded-md  border border-gray-600 bg-white px-2 py-[8px] text-left font-poppins text-[17px] font-medium text-secondBlack placeholder:font-normal focus:text-secondBlack focus:outline-none "
                placeholder="Enter New Password"
                name="new_password"
              />
              <span onClick={()=> setIsShowPass(!isShowPass)} className=" absolute right-2 top-[37px] cursor-pointer">
                {
                  isShowPass ? <BsEyeSlash size={22} className="text-secondBlack" /> : <BsEye className="text-[#717171]" size={22} />
                }
              </span>
            </div>
            <div className="mt-3 w-full relative">
              <h3 className="font-poppins text-[18px] font-medium text-black ">
                Confirm Password
              </h3>
              <input
                type="password"
                onChange={()=> setError('')}
                className=" w-full rounded-md  border border-gray-600 bg-white px-2 py-[8px] text-left font-poppins text-[17px] font-medium text-secondBlack placeholder:font-normal focus:text-secondBlack focus:outline-none "
                placeholder="Confirm Password"
                name="confirm_password"
              />
              <span className="text-red-500 font-poppins text-[14px]">{error}</span>
            </div>
            <div className="flex items-center justify-center pt-5">
              <button
                type="submit"
                className="btn flex w-fit items-center gap-1 rounded-md bg-black px-6 py-2 font-poppins text-[18px] text-white hover:bg-secondBlack hover:text-white"
              >
                {isLoading ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </PrimaryLayout>
  );
};

export default ChangePassword;
